import ApiClient from "./ApiClient";

export const fetchCurriculums = ({ token }) => {
  return ApiClient.get('/api/curriculums', {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const fetchCurriculum = ({ token, curriculumId }) => {
  return ApiClient.get('/api/curriculums/' + curriculumId, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const addCurriculum = ({ token, data }) => {
    return ApiClient.post('/api/curriculums', data, {
      headers: {
          'Authorization': 'Bearer ' + token
      }
  });
}

export const addCurriculumSubject = ({ token, data }) => {
  return ApiClient.post('/api/curriculums/add_curriculum_subject', data, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const removeCurriculumSubject = ({ token, data }) => {
  return ApiClient.post('/api/curriculums/remove_curriculum_subject', data, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const updateCurriculumSubjectPositions = ({ token, data }) => {
  return ApiClient.post('/api/curriculums/update_curriculum_subject_indexes', data, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const updateCurriculum = ({ token, curriculumId, data }) => {
  return ApiClient.put('/api/curriculums/' + curriculumId, data, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const deleteCurriculum = ({ token, curriculumId }) => {
  return ApiClient.delete('/api/curriculums/' + curriculumId, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}
