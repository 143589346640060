import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useState } from 'react';
import { AuthContext } from '../utils/AuthContext';
import { Container, Draggable } from "react-smooth-dnd";
import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { DragHandle } from '@mui/icons-material';
import { arrayMoveImmutable } from 'array-move';
import { updateSubjectPositions } from '../services/SubjectService';

export default function SubjectChangePositionsDialog({ open, setOpen, subjects, setSubjects, handleFetchSubjects, setShowSubjectForm }) {
  const handleClose = () => {
    setOpen(false);
  };

  const { auth } = useContext(AuthContext);

  const onDrop = ({ removedIndex, addedIndex }) => {
    setSubjects(subjects => arrayMoveImmutable(subjects, removedIndex, addedIndex));
  };

  async function handleUpdateSubjectPositions() {
    await updateSubjectPositions({ token: auth.token, data: {
      subjects
    }});

    handleFetchSubjects();

    setShowSubjectForm(false);
    setOpen(false);
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        Change Index Positions
      </DialogTitle>
      <DialogContent>
        <List>
          <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
            {subjects.map((subject) => (
              <Draggable key={subject.id}>
                <ListItem>
                  <ListItemText primary={subject.name} />
                  <ListItemSecondaryAction>
                    <ListItemIcon className="drag-handle">
                      <DragHandle />
                    </ListItemIcon>
                  </ListItemSecondaryAction>
                </ListItem>
              </Draggable>
            ))}
          </Container>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpdateSubjectPositions}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}