import { Alert, Autocomplete, Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, setRef } from '@mui/material'
import React, { useState, useContext, useEffect, useRef } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'
import { AuthContext } from '../utils/AuthContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCurriculum, fetchCurriculums } from '../services/CurriculumService';
import { fetchPrincipals, addPrincipal } from '../services/PrincipalService';
import { fetchSubjects } from '../services/SubjectService';
import { addStudentRecord, fetchStudentRecord, updateStudentRecord } from '../services/StudentRecordService';
import { toast } from 'react-toastify';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import ConfirmationDialog from '../components/ConfirmationDialog';
import '../components/globalState';
import moment from 'moment';


function StudentRecordForm() {

  const { auth } = useContext(AuthContext);

  const [open, setOpen] = useState(localStorage.getItem('drawer-open') == 'true');

  const { id } = useParams();

  const navigate = useNavigate();

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [principal, setPrincipal] = useState(null);

  const [curriculum, setCurriculum] = useState(null);
  const [studentRecord, setStudentRecord] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [principalFirstName, setPrincipalFirstName] = useState('');
  const [principalLastName, setPrincipalLastName] = useState('');
  const [principalMiddleName, setPrincipalMiddleName] = useState('');
  const [showAddPrincipalForm, setShowAddPrincipalForm] = useState(false);


  const [lrn, setLrn] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(moment());

  const [province, setProvince] = useState('');
  const [town, setTown] = useState('');
  const [barrio, setBarrio] = useState('');
  const [parentGuardianName, setParentGuardianName] = useState('');
  const [parentGuardianOccupation, setParentGuardianOccupation] = useState('');
  const [parentGuardianAddress, setParentGuardianAddress] = useState('');
  const [intermediateCourseCompletedAt, setIntermediateCourseCompletedAt] = useState('');
  const [intermediateCourseCompletedYear, setIntermediateCourseCompletedYear] = useState('');
  const [intermediateCourseCompletedGenAve, setIntermediateCourseCompletedGenAve] = useState('');
  const [creatorId, setCreatorId] = useState(0);
  const [dateCreated, setDateCreated] = useState(moment());
  const [editorId, setEditorId] = useState(0);
  const [dateEdited, setDateEdited] = useState(moment());

  const [noOfYears1, setNoOfYears1] = useState('');
  const [schoolYear1, setSchoolYear1] = useState('');
  const [school1, setSchool1] = useState('');

  const [noOfYears2, setNoOfYears2] = useState('');
  const [schoolYear2, setSchoolYear2] = useState('');
  const [school2, setSchool2] = useState('');

  const [noOfYears3, setNoOfYears3] = useState('');
  const [schoolYear3, setSchoolYear3] = useState('');
  const [school3, setSchool3] = useState('');

  const [noOfYears4, setNoOfYears4] = useState('');
  const [schoolYear4, setSchoolYear4] = useState('');
  const [school4, setSchool4] = useState('');

  const [totalDaysOfSchool1, setTotalDaysOfSchool1] = useState('');
  const [totalDaysPresent1, setTotalDaysPresent1] = useState('');

  const [totalDaysOfSchool2, setTotalDaysOfSchool2] = useState('');
  const [totalDaysPresent2, setTotalDaysPresent2] = useState('');

  const [totalDaysOfSchool3, setTotalDaysOfSchool3] = useState('');
  const [totalDaysPresent3, setTotalDaysPresent3] = useState('');

  const [totalDaysOfSchool4, setTotalDaysOfSchool4] = useState('');
  const [totalDaysPresent4, setTotalDaysPresent4] = useState('');

  const [isFormLoading, setFormLoading] = useState(false);

  const [curriculums, setCurriculums] = useState([]);
  const [principals, setPrincipals] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [validationErrors, setValidationErrors] = useState([]);

  const [actionTakens, setActionTakens] = useState('');

  const validationRef = useRef();
  const headerRef = useRef();

  useEffect(() => {
    handleFetchData();
  }, []);

  async function handleFetchData() {
    await handleFetchCurriculums();
    await handleFetchPrincipals();

    if (id) {
      await handleFetchStudentRecord();
    }
  }

  useEffect(() => {
    let _actionTakens = {};

    if (studentRecord != null) {
      studentRecord.student_school_years.forEach(student_school_year => {
        student_school_year.grades.forEach(grade => {
          // if (grade.subject_id == subjectId) {
          //   return 'Failed';
          // }

          _actionTakens[grade.subject_id] = grade.action_taken;
        });
      });

      setActionTakens(_actionTakens);
    }
  }, [studentRecord]);


  useEffect(() => {
    if (studentRecord && studentRecord.principal_id) {
      const existingPrincipal = principals.find(
        (p) => p.id === studentRecord.principal_id
      );
      setPrincipal(existingPrincipal);
    }
  }, [studentRecord, principals]);



  // useEffect(() => {
  //   handleFetchCurriculums();
  // }, []);

  /* async function handleFetchCurriculums() {
    setLoading(true);

    const response = await fetchCurriculums({ token: auth.token });
    const studentCurriculum = await fetchStudentRecord({ token: auth.token, studentId: id });

    setCurriculums(response.data.curriculums);

    // Check if the curriculum_id is valid and exists in the curriculums array
    const curriculumId = studentCurriculum.data.student.curriculum_id;
    const selectedCurriculum = response.data.curriculums.find(curriculum => curriculum.id === curriculumId);

    if (selectedCurriculum) {
      setCurriculum(selectedCurriculum); // Set the selected curriculum
    } else {
      console.warn("Curriculum not found for ID:", curriculumId);
    }

    setLoading(false);
  } */

  async function handleFetchPrincipals() {
    setLoading(true);
    try {

      const response = await fetchPrincipals({ token: auth.token });
      const principals = response.data.principals;
      setPrincipals(principals);
    } catch (error) {

      console.error("Error fetching principals:", error);
    } finally {
      setLoading(false);
    }
  }

  async function handleFetchCurriculums() {
    setLoading(true);
    try {

      const response = await fetchCurriculums({ token: auth.token });
      const curriculums = response.data.curriculums;
      setCurriculums(curriculums);


      if (!id) {
        console.error("Student ID is undefined");
        return;
      }

      console.log("Fetching student record for ID:", id);


      const studentCurriculum = await fetchStudentRecord({ token: auth.token, studentId: id });
      const curriculumId = studentCurriculum.data.student.curriculum_id;


      const selectedCurriculum = curriculums.find(curriculum => curriculum.id === curriculumId);


      if (selectedCurriculum) {
        setCurriculum(selectedCurriculum);
      } else {
        console.warn("Curriculum not found for ID:", curriculumId);
        setCurriculum(null);
      }

    } catch (error) {

      console.error("Error fetching curriculums or student record:", error);
      if (error.response) {
        if (error.response.status === 404) {
          console.error("Resource not found (404). Check the API endpoint and the student record.");
        } else if (error.response.status === 422) {
          console.error("Unprocessable Entity (422). Check the data being sent to the API.");
        }
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAllSubjects();
  }, []);


  async function fetchAllSubjects() {
    const response = await fetchSubjects({ token: auth.token });
    setSubjects(response.data.subjects);
  }


  // async function handleFetchCurriculum() {
  //   setLoading(true);

  //   const response = await fetchCurriculum({ token: auth.token, curriculumId: 5 });

  //   setCurriculum(response.data.curriculum);

  //   setLoading(false);
  // }

  async function handleFetchStudentRecord() {
    const response = await fetchStudentRecord({ token: auth.token, studentId: id });

    const student = response.data.student;

    setStudentRecord(student);

    setLrn(student.lrn);
    setFirstName(student.first_name);
    setLastName(student.last_name);
    setMiddleName(student.middle_name);
    setDateOfBirth(moment(student.date_of_birth));

    setProvince(student.province);
    setTown(student.town);
    setBarrio(student.barrio);
    setParentGuardianName(student.parent_guardian);
    setParentGuardianOccupation(student.parent_guardian_occupation);
    setParentGuardianAddress(student.parent_guardian_address);
    setIntermediateCourseCompletedAt(student.intermediate_course_completed_at);
    setIntermediateCourseCompletedYear(student.intermediate_course_completed_year);
    setIntermediateCourseCompletedGenAve(student.intermediate_course_completed_gen_ave.toString());
    setCreatorId(student.creator_id);
    setDateCreated(moment(student.date_created));
    setEditorId(student.editor_id);
    setDateEdited(moment(student.date_edited));

    const student_school_years = student.student_school_years;

    student_school_years.forEach(student_school_year => {
      if (student_school_year.student_level === 'First') {
        setNoOfYears1(student_school_year.total_no_of_years_in_school_to_date.toString());
        setSchoolYear1(student_school_year.school_year);
        setSchool1(student_school_year.school);

        setTotalDaysOfSchool1(student_school_year.total_days_of_school.toString());
        setTotalDaysPresent1(student_school_year.total_days_present.toString());
      } else if (student_school_year.student_level === 'Second') {
        setNoOfYears2(student_school_year.total_no_of_years_in_school_to_date.toString());
        setSchoolYear2(student_school_year.school_year);
        setSchool2(student_school_year.school);

        setTotalDaysOfSchool2(student_school_year.total_days_of_school.toString());
        setTotalDaysPresent2(student_school_year.total_days_present.toString());
      } else if (student_school_year.student_level === 'Third') {
        setNoOfYears3(student_school_year.total_no_of_years_in_school_to_date.toString());
        setSchoolYear3(student_school_year.school_year);
        setSchool3(student_school_year.school);

        setTotalDaysOfSchool3(student_school_year.total_days_of_school.toString());
        setTotalDaysPresent3(student_school_year.total_days_present.toString());
      } else if (student_school_year.student_level === 'Fourth') {
        setNoOfYears4(student_school_year.total_no_of_years_in_school_to_date.toString());
        setSchoolYear4(student_school_year.school_year);
        setSchool4(student_school_year.school);

        setTotalDaysOfSchool4(student_school_year.total_days_of_school.toString());
        setTotalDaysPresent4(student_school_year.total_days_present.toString());
      }


      student_school_year.grades.forEach(grade => {
        const finalGradeEl = document.getElementById('final-grade-' + grade.subject_id);

        if (finalGradeEl) {
          finalGradeEl.value = grade.final_grade;
        }
      });
    });


  }

  function validateForm() {
    const _validationErrors = [];

    if (!curriculum) {
      _validationErrors.curriculum = "This field is required";
    }

    if (!principal) {
      _validationErrors.principal = "This field is required";
    }

    if (!firstName.trim()) _validationErrors.firstName = 'This field is required'; {
    }

    if (!lastName.trim()) _validationErrors.lastName = 'This field is required'; {
    }


    if (!province.trim()) _validationErrors.province = 'This field is required'; {
    }

    if (!town.trim()) _validationErrors.town = 'This field is required'; {
    }

    if (!barrio.trim()) _validationErrors.barrio = 'This field is required'; {
    }

    if (!parentGuardianName.trim()) _validationErrors.parentGuardianName = 'This field is required'; {
    }

    if (!parentGuardianOccupation.trim()) _validationErrors.parentGuardianOccupation = 'This field is required'; {
    }

    if (!parentGuardianAddress.trim()) _validationErrors.parentGuardianAddress = 'This field is required'; {

    }

    if (!intermediateCourseCompletedAt.trim()) _validationErrors.intermediateCourseCompletedAt = 'This field is required'; {
    }

    if (!intermediateCourseCompletedYear.trim()) _validationErrors.intermediateCourseCompletedYear = 'This field is required'; {
    }

    if (!intermediateCourseCompletedGenAve.trim()) _validationErrors.intermediateCourseCompletedGenAve = 'This field is required'; {

    }

    // first year validations
    if (!noOfYears1.trim()) {
      _validationErrors.noOfYears1 = 'This field is required';
    } else if (!/^\d+$/.test(noOfYears1)) {
      _validationErrors.noOfYears1 = 'This field must be numeric';
    }

    if (!schoolYear1.trim()) _validationErrors.schoolYear1 = 'This field is required'; {
    }

    if (!school1.trim()) _validationErrors.school1 = 'This field is required'; {
    }

    if (!totalDaysOfSchool1.trim()) _validationErrors.totalDaysOfSchool1 = 'This field is required'; {
    }

    if (!totalDaysPresent1.trim()) _validationErrors.totalDaysPresent1 = 'This field is required'; {
    }

    if (totalDaysOfSchool1.trim() && totalDaysPresent1.trim()) {
      if (parseInt(totalDaysPresent1, 10) > parseInt(totalDaysOfSchool1, 10)) {
        _validationErrors.totalDaysPresent1 = 'This field cannot exceed the total no. of school days';
      }
    }

    // second year validations
    if (!noOfYears2.trim()) {
      _validationErrors.noOfYears2 = 'This field is required';
    } else if (!/^\d+$/.test(noOfYears2)) {
      _validationErrors.noOfYears2 = 'This field must be numeric';
    }

    if (!schoolYear2.trim()) _validationErrors.schoolYear2 = 'This field is required'; {
    }

    if (!school2.trim()) _validationErrors.school2 = 'This field is required'; {
    }

    if (!totalDaysOfSchool2.trim()) _validationErrors.totalDaysOfSchool2 = 'This field is required'; {
    }

    if (!totalDaysPresent2.trim()) _validationErrors.totalDaysPresent2 = 'This field is required'; {
    }

    if (totalDaysOfSchool2.trim() && totalDaysPresent2.trim()) {
      if (parseInt(totalDaysPresent2, 10) > parseInt(totalDaysOfSchool2, 10)) {
        _validationErrors.totalDaysPresent2 = 'This field cannot exceed the total no. of school days';
      }
    }

    // third year validations
    if (!noOfYears3.trim()) {
      _validationErrors.noOfYears3 = 'This field is required';
    } else if (!/^\d+$/.test(noOfYears3)) {
      _validationErrors.noOfYears3 = 'This field must be numeric';
    }

    if (!schoolYear3.trim()) _validationErrors.schoolYear3 = 'This field is required'; {
    }

    if (!school3.trim()) _validationErrors.school3 = 'This field is required'; {
    }

    if (!totalDaysOfSchool3.trim()) _validationErrors.totalDaysOfSchool3 = 'This field is required'; {
    }

    if (!totalDaysPresent3.trim()) _validationErrors.totalDaysPresent3 = 'This field is required'; {
    }

    if (totalDaysOfSchool3.trim() && totalDaysPresent3.trim()) {
      if (parseInt(totalDaysPresent3, 10) > parseInt(totalDaysOfSchool3, 10)) {
        _validationErrors.totalDaysPresent3 = 'This field cannot exceed the total no. of school days';
      }
    }

    // fourth year validations
    if (!noOfYears4.trim()) {
      _validationErrors.noOfYears4 = 'This field is required';
    } else if (!/^\d+$/.test(noOfYears4)) {
      _validationErrors.noOfYears4 = 'This field must be numeric';
    }

    if (!schoolYear4.trim()) _validationErrors.schoolYear4 = 'This field is required'; {
    }

    if (!school4.trim()) _validationErrors.school4 = 'This field is required'; {
    }

    if (!totalDaysOfSchool4.trim()) _validationErrors.totalDaysOfSchool4 = 'This field is required'; {
    }

    if (!totalDaysPresent4.trim()) _validationErrors.totalDaysPresent4 = 'This field is required'; {
    }

    if (totalDaysOfSchool4.trim() && totalDaysPresent4.trim()) {
      if (parseInt(totalDaysPresent4, 10) > parseInt(totalDaysOfSchool2, 10)) {
        _validationErrors.totalDaysPresent4 = 'This field cannot exceed the total no. of school days';
      }
    }


    // Validate final grades
    const grades = [];
    grades.push(...getGradesByLevel(1));
    grades.push(...getGradesByLevel(2));
    grades.push(...getGradesByLevel(3));
    grades.push(...getGradesByLevel(4));

    for (let i = 0; i < grades.length; i++) {
      const grade = grades[i];
      const finalGrade = grade.final_grade.trim();
      const subjectId = grade.subject_id;


      let subjectName = subjects.find(sub => sub.id === subjectId)?.name || `This field is required`;


      if (!subjectName) {
        for (const parent of curriculum.curriculum_subjects_1) {
          const childSubject = parent.subject.child_subjects.find(child => child.id === grades[i].subject_id);
          if (childSubject) {
            subjectName = childSubject.name;
            break;
          }
        }
      }

      if (!subjectName) {
        for (const parent of curriculum.curriculum_subjects_2) {
          const childSubject = parent.subject.child_subjects.find(child => child.id === grades[i].subject_id);
          if (childSubject) {
            subjectName = childSubject.name;
            break;
          }
        }
      }

      if (!subjectName) {
        for (const parent of curriculum.curriculum_subjects_3) {
          const childSubject = parent.subject.child_subjects.find(child => child.id === grades[i].subject_id);
          if (childSubject) {
            subjectName = childSubject.name;
            break;
          }
        }
      }

      if (!subjectName) {
        for (const parent of curriculum.curriculum_subjects_4) {
          const childSubject = parent.subject.child_subjects.find(child => child.id === grades[i].subject_id);
          if (childSubject) {
            subjectName = childSubject.name;
            break;
          }
        }
      }

      if (finalGrade === '') {
        _validationErrors[`finalGrade_${subjectId}`] = `This field is required`;
      } else if (!/^(\d+(\.\d+)?|\.\d+)$/.test(finalGrade)) { // Check if it's a valid float
        _validationErrors[`finalGrade_${subjectId}`] = `This field must be numeric`;
      }
    }

    setValidationErrors(_validationErrors);

    return Object.keys(_validationErrors).length === 0;


  }

  async function handleAddNewPrincipal() {
    try {
      const principalDetails = {
        first_name: principalFirstName,
        last_name: principalLastName,
        middle_name: principalMiddleName,
      };

      await addPrincipal({ token: auth.token, data: principalDetails });

      toast.success('Principal Added', {
        autoClose: 1000,
        hideProgressBar: true,
      });


      const updatedPrincipals = await fetchPrincipals({ token: auth.token });
      setPrincipals(updatedPrincipals.data.principals);


      setPrincipalFirstName('');
      setPrincipalMiddleName('');
      setPrincipalLastName('');
      setShowAddPrincipalForm(false);
    } catch (error) {
      console.error("Error adding principal:", error);
      toast.error('Failed to add principal');
    }
  }


  async function handleAddStudentRecord() {
    const currentDate = new Date();
    const studentRecord = {
      lrn,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      date_of_birth: dateOfBirth.format('YYYY-MM-DD'),

      province,
      town,
      barrio,
      parent_guardian: parentGuardianName,
      parent_guardian_occupation: parentGuardianOccupation,
      parent_guardian_address: parentGuardianAddress,
      intermediate_course_completed_at: intermediateCourseCompletedAt,
      intermediate_course_completed_year: intermediateCourseCompletedYear,
      intermediate_course_completed_gen_ave: intermediateCourseCompletedGenAve,
      creator_id: auth.user.id,
      date_created: currentDate.toISOString(),
      curriculum_id: curriculum.id,
      principal_id: principal.id,
      student_school_years: [
        {
          total_no_of_years_in_school_to_date: noOfYears1,
          student_level: 'First',
          school_year: schoolYear1,
          school: school1,
          total_days_of_school: totalDaysOfSchool1,
          total_days_present: totalDaysPresent1,
          grades: getGradesByLevel(1)
        },
        {
          total_no_of_years_in_school_to_date: noOfYears2,
          student_level: 'Second',
          school_year: schoolYear2,
          school: school2,
          total_days_of_school: totalDaysOfSchool2,
          total_days_present: totalDaysPresent2,
          grades: getGradesByLevel(2)
        },
        {
          total_no_of_years_in_school_to_date: noOfYears3,
          student_level: 'Third',
          school_year: schoolYear3,
          school: school3,
          total_days_of_school: totalDaysOfSchool3,
          total_days_present: totalDaysPresent3,
          grades: getGradesByLevel(3)
        },
        {
          total_no_of_years_in_school_to_date: noOfYears4,
          student_level: 'Fourth',
          school_year: schoolYear4,
          school: school4,
          total_days_of_school: totalDaysOfSchool4,
          total_days_present: totalDaysPresent4,
          grades: getGradesByLevel(4)
        }
      ]
    };

    await addStudentRecord({ token: auth.token, data: studentRecord });

    toast.success('Student Record Added', {
      autoClose: 1000,
      hideProgressBar: true
    });

    clearForm();


    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    setFormLoading(false);
  }


  async function handleUpdateStudentRecord() {
    const currentDate = new Date();
    const studentRecord = {
      lrn,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      date_of_birth: dateOfBirth.format('YYYY-MM-DD'),

      province,
      town,
      barrio,
      parent_guardian: parentGuardianName,
      parent_guardian_occupation: parentGuardianOccupation,
      parent_guardian_address: parentGuardianAddress,
      intermediate_course_completed_at: intermediateCourseCompletedAt,
      intermediate_course_completed_year: intermediateCourseCompletedYear,
      intermediate_course_completed_gen_ave: intermediateCourseCompletedGenAve,
      editor_id: auth.user.id,
      date_edited: currentDate.toISOString(),
      curriculum_id: curriculum.id,
      principal_id: principal.id,
      student_school_years: [
        {
          total_no_of_years_in_school_to_date: noOfYears1,
          student_level: 'First',
          school_year: schoolYear1,
          school: school1,
          total_days_of_school: totalDaysOfSchool1,
          total_days_present: totalDaysPresent1,
          grades: getGradesByLevel(1)
        },
        {
          total_no_of_years_in_school_to_date: noOfYears2,
          student_level: 'Second',
          school_year: schoolYear2,
          school: school2,
          total_days_of_school: totalDaysOfSchool2,
          total_days_present: totalDaysPresent2,
          grades: getGradesByLevel(2)
        },
        {
          total_no_of_years_in_school_to_date: noOfYears3,
          student_level: 'Third',
          school_year: schoolYear3,
          school: school3,
          total_days_of_school: totalDaysOfSchool3,
          total_days_present: totalDaysPresent3,
          grades: getGradesByLevel(3)
        },
        {
          total_no_of_years_in_school_to_date: noOfYears4,
          student_level: 'Fourth',
          school_year: schoolYear4,
          school: school4,
          total_days_of_school: totalDaysOfSchool4,
          total_days_present: totalDaysPresent4,
          grades: getGradesByLevel(4)
        }
      ]
    };



    await updateStudentRecord({ token: auth.token, studentId: id, data: studentRecord });

    toast.success('Student Record Updated', {
      autoClose: 1000,
      hideProgressBar: true
    });

    // clearForm();

    // headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    setFormLoading(false);
  }

  function getGradesByLevel(level) {
    if (!curriculum) {
      return [];
    }

    let grades = [];

    curriculum['curriculum_subjects_' + level].forEach(curriculum_subject => {
      const finalGradeEl = document.getElementById('final-grade-' + curriculum_subject.subject_id);
      const finalGrade = parseFloat(finalGradeEl.value);  // Convert to number for comparison

      grades.push({
        subject_id: curriculum_subject.subject_id,
        final_grade: finalGradeEl.value,
        action_taken: finalGrade === 0 ? 'Passed' : (finalGrade < 75 ? 'Failed' : 'Passed')
      });

      curriculum_subject.subject.child_subjects.forEach(child_subject => {
        const finalGradeEl = document.getElementById('final-grade-' + child_subject.id);
        const finalGrade = parseFloat(finalGradeEl.value);

        grades.push({
          subject_id: child_subject.id,
          final_grade: finalGradeEl.value,
          action_taken: finalGrade === 0 ? 'Passed' : (finalGrade < 75 ? 'Failed' : 'Passed')
        });
      });
    });

    return grades;
  }

  const handleCloseDialog = () => {
    setShowConfirmationDialog(false);
  };


  const handleConfirmDialog = () => {
    setShowConfirmationDialog(false);


    if (id) {
      handleUpdateStudentRecord();
    } else {
      handleAddStudentRecord();
    }
  };



  function handleSubmit() {

    {/* } if (!curriculum) {
      setValidationErrors(['Please select a curriculum before proceeding']);
      return;
    } */}


    const isValid = validateForm();

    if (isValid) {

      const studentRecord = {
        lrn,
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        date_of_birth: dateOfBirth.format('YYYY-MM-DD'),

        province,
        town,
        barrio,
        parent_guardian: parentGuardianName,
        parent_guardian_occupation: parentGuardianOccupation,
        parent_guardian_address: parentGuardianAddress,
        intermediate_course_completed_at: intermediateCourseCompletedAt,
        intermediate_course_completed_year: intermediateCourseCompletedYear,
        intermediate_course_completed_gen_ave: intermediateCourseCompletedGenAve,
        curriculum_id: curriculum.id,
        principal_id: principal.id,
        student_school_years: [
          {
            total_no_of_years_in_school_to_date: noOfYears1,
            student_level: 'First',
            school_year: schoolYear1,
            school: school1,
            total_days_of_school: totalDaysOfSchool1,
            total_days_present: totalDaysPresent1,
            grades: getGradesByLevel(1)
          },
          {
            total_no_of_years_in_school_to_date: noOfYears2,
            student_level: 'Second',
            school_year: schoolYear2,
            school: school2,
            total_days_of_school: totalDaysOfSchool2,
            total_days_present: totalDaysPresent2,
            grades: getGradesByLevel(2)
          },
          {
            total_no_of_years_in_school_to_date: noOfYears3,
            student_level: 'Third',
            school_year: schoolYear3,
            school: school3,
            total_days_of_school: totalDaysOfSchool3,
            total_days_present: totalDaysPresent3,
            grades: getGradesByLevel(3)
          },
          {
            total_no_of_years_in_school_to_date: noOfYears4,
            student_level: 'Fourth',
            school_year: schoolYear4,
            school: school4,
            total_days_of_school: totalDaysOfSchool4,
            total_days_present: totalDaysPresent4,
            grades: getGradesByLevel(4)
          }
        ],
      };


      setStudentRecord(studentRecord);

      setShowConfirmationDialog(true);


    } else {
      validationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  function clearForm() {
    setPrincipal(principal);
    setLrn('');
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setDateOfBirth(moment());

    setProvince('');
    setTown('');
    setBarrio('');
    setParentGuardianName('');
    setParentGuardianOccupation('');
    setParentGuardianAddress('');
    setIntermediateCourseCompletedAt('');
    setIntermediateCourseCompletedYear('');
    setIntermediateCourseCompletedGenAve('');

    setNoOfYears1('');
    setSchoolYear1('');
    setSchool1('');

    setNoOfYears2('');
    setSchoolYear2('');
    setSchool2('');

    setNoOfYears3('');
    setSchoolYear3('');
    setSchool3('');

    setNoOfYears4('');
    setSchoolYear4('');
    setSchool4('');

    setTotalDaysOfSchool1('');
    setTotalDaysPresent1('');

    setTotalDaysOfSchool2('');
    setTotalDaysPresent2('');

    setTotalDaysOfSchool3('');
    setTotalDaysPresent3('');

    setTotalDaysOfSchool4('');
    setTotalDaysPresent4('');

    curriculum.curriculum_subjects.forEach(curriculum_subject => {
      const finalGradeEl = document.getElementById('final-grade-' + curriculum_subject.subject_id);
      // const actionTakenEl = document.getElementById('action-taken-' + curriculum_subject.subject_id);
      finalGradeEl.value = '';
      // console.log(curriculum_subject.subject_id, finalGradeEl.value);
      // console.log(curriculum_subject.subject_id, actionTakenEl);

      curriculum_subject.subject.child_subjects.forEach(child_subject => {
        const finalGradeEl_child = document.getElementById('final-grade-' + child_subject.id);

        finalGradeEl_child.value = '';
      });
    });
  }

  function totalUnits(level) {
    if (curriculum == null) {
      return 0;
    }

    let total = 0;

    curriculum['curriculum_subjects_' + level].forEach(curriculum_subject => {
      total += curriculum_subject.subject.units;

      curriculum_subject.subject.child_subjects.forEach(child_subject => {
        total += child_subject.units;
      });
    });

    return total;
  }

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#ebf2fc', height: 'auto' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 3 }} >


        <Box sx={{ backgroundColor: '#ebf2fc' }}>
          <Button startIcon={<ArrowBackIcon />} onClick={() => {
            navigate('/student_records');
          }}
            sx={{
              mt: 4,
              color: '#113f70',
              '&:hover': {
                borderColor: '#3e5bb7',
                backgroundColor: '#5271ff',
                color: 'white',
              },
            }} > Back </Button>

        </Box>
        {
          isLoading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10, backgroundColor: '#ebf2fc' }}>
              <CircularProgress />
            </Box>
            :
            <Box component='form'>
              <Typography sx={{ mt: 2, mb: 1, fontSize: 30, fontWeight: 700, color: '#09213b' }}>Student Record Form</Typography>

              <Alert sx={{ display: validationErrors.length > 0 ? 'block' : 'none', scrollMarginTop: '70px' }} severity='error' ref={validationRef}>
                <ul>
                  {
                    validationErrors.map(validationError => (
                      <li>{validationError}</li>
                    ))
                  }
                </ul>
              </Alert>

              <Paper elevation={2} sx={{ py: 2, px: 3, mt: 2, borderRadius: '20px' }}>
                <Typography color="#616161" sx={{ mb: 1 }}>Curriculum</Typography>


                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '100%' }}>


                  <FormControl size="small" sx={{ width: '200px', mr: 2 }} error={!!validationErrors.curriculum}>
                    <InputLabel>Curriculum</InputLabel>
                    {curriculums && (
                      <Select
                        value={curriculum ? curriculum.id : ''}
                        label="Curriculum"
                        onChange={(e) => {
                          const id = e.target.value;
                          const selectedCurriculum = curriculums.find(c => c.id === id);
                          setCurriculum(selectedCurriculum);
                        }}
                      >
                        {curriculums.map(curriculum => (
                          <MenuItem key={curriculum.id} value={curriculum.id}>
                            {curriculum.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    {validationErrors.curriculum && (
                      <Typography color="error" variant="caption">
                        {validationErrors.curriculum}
                      </Typography>
                    )}
                  </FormControl>


                  <FormControl size="small" sx={{ width: '200px', mr: 2 }} error={!!validationErrors.principal}>
                    <InputLabel>Principal</InputLabel>
                    {principals && (
                      <Select
                        value={principal ? principal.id : ""}
                        label="Principal"
                        onChange={(e) => {
                          const id = e.target.value;
                          const selectedPrincipal = principals.find((p) => p.id === id);
                          setPrincipal(selectedPrincipal);
                        }}
                      >
                        {principals.map((p) => (
                          <MenuItem key={p.id} value={p.id}>
                            {`${p.first_name} ${p.middle_name || ""} ${p.last_name}`.trim()}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    {validationErrors.principal && (
                      <Typography color="error" variant="caption" sx={{ marginTop: '4px', display: 'block' }}>
                        {validationErrors.principal}
                      </Typography>
                    )}
                  </FormControl>
                </Box>








                <Typography color="#616161" sx={{ mb: 1, mt: 2 }}>Student Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <TextField
                      size='small'
                      margin='none'
                      label="LRN"
                      fullWidth
                      name="LRN"
                      value={lrn}
                      onChange={(e) => {
                        setLrn(e.target.value);
                      }} />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="First Name" fullWidth name="First Name" value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      error={!!validationErrors.firstName}
                      helperText={validationErrors.firstName}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="Last Name" fullWidth name="Last Name" value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      error={!!validationErrors.lastName}
                      helperText={validationErrors.lastName}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField size='small' margin='none' label="Middle Name" fullWidth name="Middle Name" value={middleName}
                      onChange={(e) => {
                        setMiddleName(e.target.value);
                      }}
                      error={!!validationErrors.middleName}
                      helperText={validationErrors.middleName}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '1px', alignItems: 'center' }}>
                  <Grid item s={2}>
                    {/* <TextField size='small' margin='none' type='date' label='Date of Birth' fullWidth name='Date of Birth' value={dateOfBirth}
                      onChange={(e) => {
                        setDateOfBirth(e.target.value);
                      }} /> */}
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          format='YYYY-MM-DD'
                          label="Date of Birth"
                          slotProps={{ textField: { size: 'small' } }}
                          value={dateOfBirth}
                          onChange={(e) => {
                            setDateOfBirth(e);
                          }}
                          error={!!validationErrors.dateOfBirth}
                          helperText={validationErrors.dateOfBirth}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                  </Grid>
                  { /* <Grid item xs={2}>
                    <TextField sx={{ mt: 1 }} size='small' margin='none' label="Place of Birth" fullWidth name='Place of Birth' value={placeOfBirth}
                      onChange={(e) => {
                        setPlaceOfBirth(e.target.value);
                      }} />
                  </Grid> */}
                </Grid>

                <Typography color="#616161" sx={{ mb: 1, mt: 2 }}>Student Address</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="Province" fullWidth name="Province" value={province}
                      onChange={(e) => {
                        setProvince(e.target.value);
                      }}
                      error={!!validationErrors.province}
                      helperText={validationErrors.province}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="Town" fullWidth name="Town" value={town}
                      onChange={(e) => {
                        setTown(e.target.value);
                      }}
                      error={!!validationErrors.town}
                      helperText={validationErrors.town}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="Barrio" fullWidth name="Barrio" value={barrio}
                      onChange={(e) => {
                        setBarrio(e.target.value);
                      }}
                      error={!!validationErrors.barrio}
                      helperText={validationErrors.barrio}
                    />
                  </Grid>
                </Grid>

                <Typography color="#616161" sx={{ mb: 1, mt: 2 }}>Parent / Guardian</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="Name" fullWidth name="Guardian Name" value={parentGuardianName}
                      onChange={(e) => {
                        setParentGuardianName(e.target.value);
                      }}
                      error={!!validationErrors.parentGuardianName}
                      helperText={validationErrors.parentGuardianName}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="Occupation" fullWidth name="Occupation" value={parentGuardianOccupation}
                      onChange={(e) => {
                        setParentGuardianOccupation(e.target.value);
                      }}
                      error={!!validationErrors.parentGuardianOccupation}
                      helperText={validationErrors.parentGuardianOccupation}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="Address" fullWidth name="Address" value={parentGuardianAddress}
                      onChange={(e) => {
                        setParentGuardianAddress(e.target.value);
                      }}
                      error={!!validationErrors.parentGuardianAddress}
                      helperText={validationErrors.parentGuardianAddress}
                    />
                  </Grid>
                </Grid>

                <Typography color="#616161" sx={{ mb: 1, mt: 2 }}>Intermediate School</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField required size='small' margin='none' label="Intermediate Course Completed (School)" fullWidth name="Intermediate Course Completed (School)" value={intermediateCourseCompletedAt}
                      onChange={(e) => {
                        setIntermediateCourseCompletedAt(e.target.value);
                      }}
                      error={!!validationErrors.intermediateCourseCompletedAt}
                      helperText={validationErrors.intermediateCourseCompletedAt}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="Year" fullWidth name="Year" value={intermediateCourseCompletedYear}
                      onChange={(e) => {
                        setIntermediateCourseCompletedYear(e.target.value);
                      }}
                      error={!!validationErrors.intermediateCourseCompletedYear}
                      helperText={validationErrors.intermediateCourseCompletedYear}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField required size='small' margin='none' label="General Average" fullWidth name="General Average" value={intermediateCourseCompletedGenAve}
                      onChange={(e) => {
                        setIntermediateCourseCompletedGenAve(e.target.value);
                      }}
                      error={!!validationErrors.intermediateCourseCompletedGenAve}
                      helperText={validationErrors.intermediateCourseCompletedGenAve}
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Box sx={{ justifyContent: 'space-between' }}>


                {/* FIRST YEAR */}

                <Paper elevation={2} sx={{ py: 2, px: 3, mt: 3, width: '100%', mr: 2, borderRadius: '20px' }}>
                  <Typography sx={{ fontSize: 18, fontWeight: '600', mb: 2 }}>First Year</Typography>
                  <Box sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>Total No. of Years in School to Date</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField required className='table-input' size='small' sx={{ width: 188 }} name="No Of Years" value={noOfYears1}
                          onChange={(e) => {
                            setNoOfYears1(e.target.value);
                          }}
                          error={!!validationErrors.noOfYears1}
                          helperText={validationErrors.noOfYears1}
                        />
                      </Grid>
                    </Grid>


                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>CLASSIFIED AS First Year School Year</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField required className='table-input' size='small' sx={{ width: 188 }} name="School Year" value={schoolYear1}
                          onChange={(e) => {
                            setSchoolYear1(e.target.value);
                          }}
                          error={!!validationErrors.schoolYear1}
                          helperText={validationErrors.schoolYear1}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>School</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField required className='table-input' size='small' sx={{ width: 400 }} name="School" value={school1}
                          onChange={(e) => {
                            setSchool1(e.target.value);
                          }}
                          error={!!validationErrors.school1}
                          helperText={validationErrors.school1}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Table for First Year */}
                  <Table sx={{ minWidth: 750, margin: 'auto' }} aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px', width: '33%', textAlign: 'left' }}>Subjects</TableCell>
                        <TableCell sx={{ padding: '8px 16px', width: '33%', textAlign: 'center' }}>Final Grade</TableCell>
                        <TableCell sx={{ padding: '8px 16px', width: '34%', textAlign: 'center' }}>Units Earned</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {curriculum && curriculum.curriculum_subjects_1.map(curriculum_subject => (
                        <React.Fragment key={curriculum_subject.id}>
                          <TableRow>
                            <TableCell sx={{ padding: '8px 16px' }}>
                              {curriculum_subject.subject.name}
                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                              <TextField
                                id={`final-grade-${curriculum_subject.subject.id}`}
                                className="table-input"
                                size="small"
                                margin="none"
                                sx={{ width: 90, fontSize: '0.5rem' }}
                                error={!!validationErrors[`finalGrade_${curriculum_subject.subject.id}`]}
                                helperText={validationErrors[`finalGrade_${curriculum_subject.subject.id}`]}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{curriculum_subject.subject.units}</TableCell>
                          </TableRow>

                          {curriculum_subject.subject.child_subjects.map(child_subject => (
                            <TableRow key={child_subject.id}>
                              <TableCell sx={{ padding: '8px 16px', paddingLeft: '24px' }}>
                                <span style={{ marginLeft: 10, display: 'inline-block' }}>* {child_subject.name || 'Unknown Subject'}</span>
                              </TableCell>
                              <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                                <TextField
                                  id={'final-grade-' + child_subject.id}
                                  className="table-input"
                                  size="small"
                                  margin="none"
                                  sx={{ width: 90, fontSize: '0.5rem' }}
                                  error={!!validationErrors[`finalGrade_${child_subject.id}`]}
                                  helperText={validationErrors[`finalGrade_${child_subject.id}`]}
                                />
                              </TableCell>
                              <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{child_subject.units}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ))}

                      <TableRow>
                        <TableCell colSpan={3}>&nbsp;</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px' }}>Total Days of School</TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                          <TextField
                            required
                            className="table-input"
                            size="small"
                            margin="none"
                            sx={{ width: 90, fontSize: '0.5rem' }}
                            value={totalDaysOfSchool1}
                            onChange={(e) => setTotalDaysOfSchool1(e.target.value)}
                            error={!!validationErrors.totalDaysOfSchool1}
                            helperText={validationErrors.totalDaysOfSchool1}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>Total Units</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px' }}>Total Days Present</TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                          <TextField
                            required
                            className="table-input"
                            size="small"
                            margin="none"
                            sx={{ width: 90, fontSize: '0.5rem' }}
                            value={totalDaysPresent1}
                            onChange={(e) => setTotalDaysPresent1(e.target.value)}
                            error={!!validationErrors.totalDaysPresent1}
                            helperText={validationErrors.totalDaysPresent1}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{totalUnits(1)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>




                </Paper>


                {/* SECOND YEAR */}

                <Paper elevation={2} sx={{ py: 2, px: 3, mt: 3, width: '100%', mr: 2, borderRadius: '20px' }}>
                  <Typography sx={{ fontSize: 18, fontWeight: '600', mb: 2 }}>Second Year</Typography>
                  <Box sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>Total No. of Years in School to Date</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField required className='table-input' size='small' sx={{ width: 188 }} name="No Of Years" value={noOfYears2}
                          onChange={(e) => {
                            setNoOfYears2(e.target.value);
                          }}
                          error={!!validationErrors.noOfYears2}
                          helperText={validationErrors.noOfYears2}
                        />
                      </Grid>
                    </Grid>


                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>CLASSIFIED AS Second Year School Year</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField required className='table-input' size='small' sx={{ width: 188 }} name="School Year" value={schoolYear2}
                          onChange={(e) => {
                            setSchoolYear2(e.target.value);
                          }}

                          error={!!validationErrors.schoolYear2}
                          helperText={validationErrors.schoolYear2}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>School</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField required className='table-input' size='small' sx={{ width: 400 }} name="School" value={school2}
                          onChange={(e) => {
                            setSchool2(e.target.value);
                          }}
                          error={!!validationErrors.school2}
                          helperText={validationErrors.school2}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Table for Second year */}
                  <Table sx={{ minWidth: 750, margin: 'auto' }} aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px', width: '33%', textAlign: 'left' }}>Subjects</TableCell>
                        <TableCell sx={{ padding: '8px 16px', width: '33%', textAlign: 'center' }}>Final Grade</TableCell>
                        <TableCell sx={{ padding: '8px 16px', width: '34%', textAlign: 'center' }}>Units Earned</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {curriculum && curriculum.curriculum_subjects_2.map(curriculum_subject => (
                        <React.Fragment key={curriculum_subject.id}>
                          <TableRow>
                            <TableCell sx={{ padding: '8px 16px' }}>
                              {curriculum_subject.subject.name}
                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                              <TextField
                                id={`final-grade-${curriculum_subject.subject.id}`}
                                className="table-input"
                                size="small"
                                margin="none"
                                sx={{ width: 90, fontSize: '0.5rem' }}
                                error={!!validationErrors[`finalGrade_${curriculum_subject.subject.id}`]}
                                helperText={validationErrors[`finalGrade_${curriculum_subject.subject.id}`]}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{curriculum_subject.subject.units}</TableCell>
                          </TableRow>

                          {curriculum_subject.subject.child_subjects.map(child_subject => (
                            <TableRow key={child_subject.id}>
                              <TableCell sx={{ padding: '8px 16px', paddingLeft: '24px' }}>
                                <span style={{ marginLeft: 10, display: 'inline-block' }}>* {child_subject.name}</span>
                              </TableCell>
                              <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                                <TextField
                                  id={`final-grade-${child_subject.id}`}
                                  className="table-input"
                                  size="small"
                                  margin="none"
                                  sx={{ width: 90, fontSize: '0.5rem' }}
                                  error={!!validationErrors[`finalGrade_${child_subject.id}`]}
                                  helperText={validationErrors[`finalGrade_${child_subject.id}`]}
                                />
                              </TableCell>
                              <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{child_subject.units}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ))}

                      <TableRow>
                        <TableCell colSpan={3}>&nbsp;</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px' }}>Total Days of School</TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                          <TextField
                            required
                            className="table-input"
                            size="small"
                            margin="none"
                            sx={{ width: 90, fontSize: '0.5rem' }}
                            value={totalDaysOfSchool2}
                            onChange={(e) => setTotalDaysOfSchool2(e.target.value)}
                            error={!!validationErrors.totalDaysOfSchool2}
                            helperText={validationErrors.totalDaysOfSchool2}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>Total Units</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px' }}>Total Days Present</TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                          <TextField
                            required
                            className="table-input"
                            size="small"
                            margin="none"
                            sx={{ width: 90, fontSize: '0.5rem' }}
                            value={totalDaysPresent2}
                            onChange={(e) => setTotalDaysPresent2(e.target.value)}
                            error={!!validationErrors.totalDaysPresent2}
                            helperText={validationErrors.totalDaysPresent2}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{totalUnits(2)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                </Paper>
              </Box>



              {/* THIRD YEAR */}

              <Box sx={{ justifyContent: 'space-between' }}>
                <Paper elevation={2} sx={{ py: 2, px: 3, mt: 3, width: '100%', mr: 2, borderRadius: '20px' }}>
                  <Typography sx={{ fontSize: 18, fontWeight: '600', mb: 2 }}>Third Year</Typography>
                  <Box sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>Total No. of Years in School to Date</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField className='table-input' size='small' sx={{ width: 188 }} name="No Of Years" value={noOfYears3}
                          onChange={(e) => {
                            setNoOfYears3(e.target.value);
                          }}
                          error={!!validationErrors.noOfYears3}
                          helperText={validationErrors.noOfYears3}
                        />
                      </Grid>
                    </Grid>


                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>CLASSIFIED AS Third Year School Year</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField className='table-input' size='small' sx={{ width: 188 }} name="School Year" value={schoolYear3}
                          onChange={(e) => {
                            setSchoolYear3(e.target.value);
                          }}
                          error={!!validationErrors.schoolYear3}
                          helperText={validationErrors.schoolYear3}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>School</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField className='table-input' size='small' sx={{ width: 400 }} name="School" value={school3}
                          onChange={(e) => {
                            setSchool3(e.target.value);
                          }}
                          error={!!validationErrors.school3}
                          helperText={validationErrors.school3}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Table for Third Year*/}
                  <Table sx={{ minWidth: 750, margin: 'auto' }} aria-label="simple table" size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px', width: '33%', textAlign: 'left' }}>Subjects</TableCell>
                        <TableCell sx={{ padding: '8px 16px', width: '33%', textAlign: 'center' }}>Final Grade</TableCell>
                        <TableCell sx={{ padding: '8px 16px', width: '34%', textAlign: 'center' }}>Units Earned</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {curriculum && curriculum.curriculum_subjects_3.map(curriculum_subject => (
                        <React.Fragment key={curriculum_subject.id}>
                          <TableRow>
                            <TableCell sx={{ padding: '8px 16px' }}>
                              {curriculum_subject.subject.name}
                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                              <TextField
                                id={`final-grade-${curriculum_subject.subject.id}`}
                                className="table-input"
                                size="small"
                                margin="none"
                                sx={{ width: 90, fontSize: '0.5rem' }}
                                error={!!validationErrors[`finalGrade_${curriculum_subject.subject.id}`]}
                                helperText={validationErrors[`finalGrade_${curriculum_subject.subject.id}`]}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{curriculum_subject.subject.units}</TableCell>
                          </TableRow>

                          {curriculum_subject.subject.child_subjects.map(child_subject => (
                            <TableRow key={child_subject.id}>
                              <TableCell sx={{ padding: '8px 16px', paddingLeft: '24px' }}>
                                <span style={{ marginLeft: 10, display: 'inline-block' }}>* {child_subject.name}</span>
                              </TableCell>
                              <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                                <TextField
                                  id={`final-grade-${child_subject.id}`}
                                  className="table-input"
                                  size="small"
                                  margin="none"
                                  sx={{ width: 90, fontSize: '0.5rem' }}
                                  error={!!validationErrors[`finalGrade_${child_subject.id}`]}
                                  helperText={validationErrors[`finalGrade_${child_subject.id}`]}
                                />
                              </TableCell>
                              <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{child_subject.units}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ))}

                      <TableRow>
                        <TableCell colSpan={3}>&nbsp;</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px' }}>Total Days of School</TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                          <TextField
                            required
                            className='table-input'
                            size='small'
                            margin='none'
                            sx={{ width: 90, fontSize: '0.5rem' }}
                            value={totalDaysOfSchool3}
                            onChange={(e) => {
                              setTotalDaysOfSchool3(e.target.value);
                            }}
                            error={!!validationErrors.totalDaysOfSchool3}
                            helperText={validationErrors.totalDaysOfSchool3}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>Total Units</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px' }}>Total Days Present</TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                          <TextField
                            required
                            className='table-input'
                            size='small'
                            margin='none'
                            sx={{ width: 90, fontSize: '0.5rem' }}
                            value={totalDaysPresent3}
                            onChange={(e) => {
                              setTotalDaysPresent3(e.target.value);
                            }}
                            error={!!validationErrors.totalDaysPresent3}
                            helperText={validationErrors.totalDaysPresent3}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{totalUnits(3)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>


                {/* FOURTH YEAR */}

                <Paper elevation={2} sx={{ py: 2, px: 3, mt: 3, width: '100%', mr: 2, borderRadius: '20px' }}>
                  <Typography sx={{ fontSize: 18, fontWeight: '600', mb: 2 }}>Fourth Year</Typography>
                  <Box sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>Total No. of Years in School to Date</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField className='table-input' size='small' sx={{ width: 188 }} name="No Of Years" value={noOfYears4}
                          onChange={(e) => {
                            setNoOfYears4(e.target.value);
                          }}
                          error={!!validationErrors.noOfYears4}
                          helperText={validationErrors.noOfYears4}
                        />
                      </Grid>
                    </Grid>


                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>CLASSIFIED AS Fourth Year School Year</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField className='table-input' size='small' sx={{ width: 188 }} name="School Year" value={schoolYear4}
                          onChange={(e) => {
                            setSchoolYear4(e.target.value);
                          }}
                          error={!!validationErrors.schoolYear4}
                          helperText={validationErrors.schoolYear4}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>School</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField className='table-input' size='small' sx={{ width: 400 }} name="School" value={school4}
                          onChange={(e) => {
                            setSchool4(e.target.value);
                          }}
                          error={!!validationErrors.school4}
                          helperText={validationErrors.school4}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Table for Fourth year */}
                  <Table sx={{ minWidth: 750, margin: 'auto' }} aria-label="simple table" size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px', width: '33%', textAlign: 'left' }}>Subjects</TableCell>
                        <TableCell sx={{ padding: '8px 16px', width: '33%', textAlign: 'center' }}>Final Grade</TableCell>
                        <TableCell sx={{ padding: '8px 16px', width: '34%', textAlign: 'center' }}>Units Earned</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {curriculum && curriculum.curriculum_subjects_4.map(curriculum_subject => (
                        <React.Fragment key={curriculum_subject.id}>
                          <TableRow>
                            <TableCell sx={{ padding: '8px 16px' }}>
                              {curriculum_subject.subject.name}
                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                              <TextField
                                id={`final-grade-${curriculum_subject.subject.id}`}
                                className="table-input"
                                size="small"
                                margin="none"
                                sx={{ width: 90, fontSize: '0.5rem' }}
                                error={!!validationErrors[`finalGrade_${curriculum_subject.subject.id}`]}
                                helperText={validationErrors[`finalGrade_${curriculum_subject.subject.id}`]}
                              />

                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{curriculum_subject.subject.units}</TableCell>
                          </TableRow>

                          {curriculum_subject.subject.child_subjects.map(child_subject => (
                            <TableRow key={child_subject.id}>
                              <TableCell sx={{ padding: '8px 16px', paddingLeft: '24px' }}>
                                <span style={{ marginLeft: 10, display: 'inline-block' }}>* {child_subject.name}</span>
                              </TableCell>
                              <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                                <TextField
                                  id={`final-grade-${child_subject.id}`}
                                  className="table-input"
                                  size="small"
                                  margin="none"
                                  sx={{ width: 90, fontSize: '0.5rem' }}
                                  error={!!validationErrors[`finalGrade_${child_subject.id}`]}
                                  helperText={validationErrors[`finalGrade_${child_subject.id}`]}
                                />
                              </TableCell>
                              <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{child_subject.units}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ))}

                      <TableRow>
                        <TableCell colSpan={3}>&nbsp;</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px' }}>Total Days of School</TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                          <TextField
                            required
                            className='table-input'
                            size='small'
                            margin='none'
                            sx={{ width: 90, fontSize: '0.5rem' }}
                            value={totalDaysOfSchool4}
                            onChange={(e) => {
                              setTotalDaysOfSchool4(e.target.value);
                            }}
                            error={!!validationErrors.totalDaysOfSchool4}
                            helperText={validationErrors.totalDaysOfSchool4}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>Total Units</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ padding: '8px 16px' }}>Total Days Present</TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>
                          <TextField
                            required
                            className='table-input'
                            size='small'
                            margin='none'
                            sx={{ width: 90, fontSize: '0.5rem' }}
                            value={totalDaysPresent4}
                            onChange={(e) => {
                              setTotalDaysPresent4(e.target.value);
                            }}
                            error={!!validationErrors.totalDaysPresent4}
                            helperText={validationErrors.totalDaysPresent4}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px', textAlign: 'center' }}>{totalUnits(4)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>


              </Box>
              <Box sx={{ justifyContent: 'start', mt: 3 }}>
                <Button onClick={(e) => {
                  e.preventDefault();

                  // handleAddStudentRecord();
                  handleSubmit();
                }} variant='contained' type='submit' disabled={isFormLoading}
                  sx={{
                    width: '140px',
                    backgroundColor: '#113f70',
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#5271ff',
                      color: 'white'
                    }
                  }}
                >{id ? 'Update' : 'Submit'} </Button>
              </Box>
            </Box>
        }




        <Box sx={{ height: 50 }}></Box>

        <ConfirmationDialog
          open={showConfirmationDialog}
          handleClose={handleCloseDialog}
          handleConfirm={handleConfirmDialog}
          studentRecord={studentRecord}
        />

      </Main>
    </Box>
  )
}

export default StudentRecordForm;