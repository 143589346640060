import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Tooltip, CircularProgress, Pagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function PrincipalsTable({
    principals,
    setFormMode,
    setSelectedPrincipal,
    setPrincipalToDelete,
    setShowDeleteDialog,
    setShowPrincipalForm, // Added to control dialog visibility
    isLoading,
}) {
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;

    const totalPages = Math.ceil(principals.length / rowsPerPage);

    const currentPagePrincipals = principals.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="principals table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: '300' }}>Principal Name</TableCell>
                            <TableCell sx={{ fontWeight: '300' }}>Position</TableCell>
                            <TableCell align="right" sx={{ fontWeight: '300', paddingRight: 7 }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <CircularProgress size={25} />
                                </TableCell>
                            </TableRow>
                        ) : (
                            currentPagePrincipals.map((principal) => (
                                <TableRow key={principal.id}>
                                    <TableCell>
                                        {`${principal.first_name} ${principal.middle_name || ''} ${principal.last_name}`.trim()}
                                    </TableCell>
                                    <TableCell>{principal.position}</TableCell>

                                    
                                    <TableCell align="right" sx={{ display: 'flex', justifyContent: 'right', paddingRight: 5}}>
                                        {/* Edit Button */}
                                        <Tooltip title="Edit Principal">
                                            <IconButton
                                                sx={{
                                                    mr: 1,
                                                    color: '#113f70',
                                                    '&:hover': {
                                                        color: '#5271ff',
                                                    },
                                                }}
                                                size="small"
                                                onClick={() => {
                                                    console.log('Editing Principal:', principal); // Debug
                                                    setSelectedPrincipal(principal); // Set principal to edit
                                                    setFormMode('update'); // Switch to update mode
                                                    setShowPrincipalForm(true); // Open the dialog
                                                }}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>

                                        {/* Delete Button */}
                                        <Tooltip title="Delete Principal">
                                            <IconButton
                                                sx={{
                                                    color: '#113f70',
                                                    '&:hover': {
                                                        color: '#5271ff',
                                                    },
                                                }}
                                                size="small"
                                                onClick={() => {
                                                    setPrincipalToDelete(principal);
                                                    setShowDeleteDialog(true);
                                                }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                color="primary"
                size="small"
                sx={{ mt: 2, display: 'flex', justifyContent: 'right' }}
            />
        </Paper>
    );
}