import ApiClient from "./ApiClient";

export const fetchPrincipals = ({ token }) => {
    return ApiClient.get('/api/principals', {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}

export const addPrincipal = ({ token, data }) => {
    return ApiClient.post('/api/principals', data, {
      headers: {
          'Authorization': 'Bearer ' + token
      }
  });
}

export const updatePrincipal = ({ token, principalId, data }) => {
    return ApiClient.put('/api/principals/' + principalId, data, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}

export const deletePrincipal = ({ token, principalId }) => {
    return ApiClient.delete('/api/principals/' + principalId, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}