import { Box, Button, Typography, Paper } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'
import { AuthContext } from '../utils/AuthContext';
import CurriculumFormDialog from '../components/CurriculumFormDialog';
import CurriculumsTable from '../components/CurriculumsTable';
import { deleteCurriculum, fetchCurriculums } from '../services/CurriculumService';
import AlertDialog from '../components/AlertDialog';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';

function Curriculums() {

  const { auth } = useContext(AuthContext);

  const [open, setOpen] = useState(localStorage.getItem('drawer-open') == 'true');

  

  const [showCurriculumForm, setShowCurriculumForm] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [formMode, setFormMode] = useState('add');

  const [curriculums, setCurriculums] = useState([]);

  const [selectedCurriculum, setSelectedCurriculum] = useState(null);
  const [curriculumToDelete, setCurriculumToDelete] = useState(null);

  const [fetchingCurriculum, setFetchingCurriculum] = useState(false);

  useEffect(() => {
    handleFetchCurriculums();
  }, []);


  async function handleFetchCurriculums() {
    setFetchingCurriculum(true);

    const response = await fetchCurriculums({ token: auth.token });

    setCurriculums(response.data.curriculums);

    setFetchingCurriculum(false);
  }

  async function handleDeleteCurriculum() {
    await deleteCurriculum({ token: auth.token, curriculumId: curriculumToDelete.id });

    toast.success('Curriculum Deleted', {
      autoClose: 1000,
      hideProgressBar: true
    });

    handleFetchCurriculums();
  }


  return (
    <Box sx={{ display: 'flex', background: '#ebf2fc', height:'100vh' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 3 }} >

      <Paper 
      elevation={0}
      sx={{
          p: 3,
          backgroundColor: '#ffffff',
          borderRadius: '20px',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, mb: 1 }}>
        <Typography variant='h4' sx={{ mt: 1, fontWeight: 700,  color:'#09213b'}}> Curriculums </Typography>
          
          <Button variant='contained' 
          startIcon={<AddIcon />}
          sx={{
                padding: 1.7,
                backgroundColor:'#113f70',
                color: 'white',  
               
                borderRadius: '10px',
                textTransform: 'none',
                '&:hover': {
                  borderColor: '#3e5bb7', 
                  backgroundColor: '#5271ff', 
                  color: 'white' 
                }
              }}  onClick={() => {
            setShowCurriculumForm(true);
            setFormMode('add');
          }}>Add Curriculum</Button>
        </Box>

        </Paper>

        <CurriculumsTable
          curriculums={curriculums}
          setShowCurriculumForm={setShowCurriculumForm}
          setFormMode={setFormMode}
          setSelectedCurriculum={setSelectedCurriculum}
          setShowDeleteDialog={setShowDeleteDialog}
          setCurriculumToDelete={setCurriculumToDelete}
          isLoading={fetchingCurriculum} />

        {/* Dialogs */}
        <CurriculumFormDialog
          open={showCurriculumForm}
          setOpen={setShowCurriculumForm}
          mode={formMode}
          curriculum={selectedCurriculum}
          setCurriculum={setSelectedCurriculum}
          handleFetchCurriculums={handleFetchCurriculums} />

        <AlertDialog title="Delete Curriculum"
          content={'Do you want to delete "' + (curriculumToDelete ? curriculumToDelete.name : '') + '"?'}
          positiveButtonLabel="Delete"
          negativeButtonLabel="Cancel"
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          callback={() => {
            handleDeleteCurriculum();
            setShowDeleteDialog(false);
          }} />

        <Box sx={{ height: 50 }}></Box>

      </Main>
    </Box>
  )
}

export default Curriculums;