import axios from "axios";
import global from "../utils/Global";

// Create an Axios instance with a base URL
const ApiClient = axios.create({
  baseURL: global.baseURL
});

// Export the Axios instance for use in service files
export default ApiClient;
