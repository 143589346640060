import { Box, Paper, CircularProgress, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useEffect, useState } from 'react';
import { addSubject, fetchSubjects, updateSubject } from '../services/SubjectService';
import { AuthContext } from '../utils/AuthContext';
import { toast } from 'react-toastify';
import { addCurriculum, addCurriculumSubject, updateCurriculum } from '../services/CurriculumService';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from 'use-debounce';

export default function SelectSubjectDialog({ open, setOpen, year, curriculumId, curriculum, handleFetchCurriculum }) {
  const handleClose = () => {
    setOpen(false);
  };

  const { auth } = useContext(AuthContext);

  const [searchQuery, setSearchQuery] = useState('');

  const [debouncedValue] = useDebounce(searchQuery, 500);

  const [fetchingSubjects, setFetchingSubjects] = useState(false);

  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    handleFetchSubjects();
  }, []);

  useEffect(() => {
    handleFetchSubjects();
  }, [debouncedValue]);

  async function handleFetchSubjects() {
    setFetchingSubjects(true);

    const response = await fetchSubjects({ token: auth.token, search_query: searchQuery });

    setSubjects(response.data.subjects);

    setFetchingSubjects(false);
  }

  async function handleAddCurriculumSubject(subjectId) {
    const response = await addCurriculumSubject({
      token: auth.token, data: {
        subject_id: subjectId,
        curriculum_id: curriculumId,
        year,
      }
    }).catch(err => {
      if (err.response.status == 422) {
        toast.error(err.response.data.message, {
          autoClose: 1000,
          hideProgressBar: true
        });
      }
    });

    if (response) {
      handleFetchCurriculum();

      toast.success('Subject Added', {
        autoClose: 1000,
        hideProgressBar: true
      });
    }
  }

  function checkIfAlreadyAdded(subject) {

    if (!curriculum) return;

    for (let i = 0; i < curriculum.curriculum_subjects.length; i++) {
      if (subject.id === curriculum.curriculum_subjects[i].subject_id) {
        return true;
      }
    }

    return false;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        Select Subject
      </DialogTitle>
      <DialogContent>
        <TextField
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          sx={{ mb: 2 }}
          label="Search..."
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }} />

        <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
        <Table aria-label="simple table" sx={{ minWidth: 450 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '200' }}>Subject</TableCell>
              <TableCell sx={{ fontWeight: '300' }}>Units</TableCell>
              <TableCell sx={{ fontWeight: '300' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              fetchingSubjects ?
                <TableRow>
                  <TableCell colSpan={3} align='center'>
                    <CircularProgress size={25} />
                  </TableCell>
                </TableRow>
                :
                subjects.length > 0 && subjects.map((subject) => (
                  <>
                    <TableRow
                      key={subject.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell scope="row">
                        {subject.name}
                      </TableCell>
                      <TableCell>{subject.units}</TableCell>
                      <TableCell>
                        <Button
                          size='small'
                          variant='contained'
                          onClick={() => {
                            handleAddCurriculumSubject(subject.id);
                          }}
                          disabled={checkIfAlreadyAdded(subject)}>Add</Button>
                      </TableCell>
                    </TableRow>
                  </>
                ))
            }
          </TableBody>
        </Table>
      </Paper>


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}
          sx={{
            mr: 1,
            color: '#113f70',
            '&:hover': {
              color: '#5271ff'
            }
          }}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}