import { Button, Checkbox, FormControlLabel, Paper, TextField, Typography } from "@mui/material"
import { Box, Container } from "@mui/system"
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NavLink } from 'react-router-dom';
import { AuthContext } from "../utils/AuthContext";

import { LoadingButton } from "@mui/lab";
import { login } from "../services/AuthController";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const { auth, setAuth } = useContext(AuthContext);

  useEffect(() => {
    if (auth.token) {
      handleNavigate();
    }
  }, []);

  function handleNavigate() {
    navigate('/student_records');
  }

  async function handleLogin(event) {

    event.preventDefault();

    if (loading === true) {

      return;
    }


    try {
      setLoading(true);

      const response = await login({ email, password }).catch(err => {
        setLoading(false);
      });

      const user = response.data.user;
      const token = response.data.token;

      localStorage.setItem('api-token', token);
      localStorage.setItem('current-user', JSON.stringify(user));

      setAuth({
        token,
        user
      });


      setTimeout(() => {
        setLoading(false);
        handleNavigate();
      }, 200);

    } catch (err) {
      toast.error("Incorrect Email or Password", {
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
  }


  return (
    <>
      <div>
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100vh',
            }}
          >

            <Box
              sx={{
                width: '1000px',
                height: '530px',
                color: '#fff',
                borderRadius: 5,
                background: '#103e6f',
                pb: 10,
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px, rgba(0, 0, 0, 0.23) 0px 2px 4px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <img
                src="/images/bg.png"
                alt="background image"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '20px',
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px, rgba(0, 0, 0, 0.23) 0px 2px 4px',
                }}
              />


              <Box
                sx={{
                  position: 'absolute',
                  top: '250px',
                  left: '20px',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <img
                  style={{
                    width: '95px',
                    marginRight: '15px',
                  }}
                  src="/school_logo.png"
                  alt="School Logo"
                  id="logo"
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography fontSize={35} fontWeight={600} sx={{ fontFamily: 'Poppins' }}>
                    Jose J. Mariano
                  </Typography>
                  <Typography fontSize={27} fontWeight={600} sx={{ fontFamily: 'Poppins' }}>
                    Memorial High School
                  </Typography>
                </Box>
              </Box>
            </Box>


            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                right: '6.5%',
                transform: 'translateY(-50%)',
                zIndex: 1,
                width: '530px',
                height: '611px',
                color: '#ebf2fc',
                borderRadius: 5,
                background: '#fff',
              }}
            >
              <Box sx={{ mx: 8 }}>
                <Typography fontSize={30} fontWeight={600} align="center" sx={{ fontFamily: 'Poppins', mt: 10, color: '#103e6f' }}>
                  Login
                </Typography>
                <Box component="form" method="POST" noValidate onSubmit={handleLogin} sx={{ mt: 6 }}>
                  <Typography fontSize={15} fontWeight={600} sx={{ fontFamily: 'Poppins', color: '#103e6f' }}> Email </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      mt: 1,
                      fontFamily: 'Poppins',
                      '& .MuiOutlinedInput-root': {
                        height: 50,
                        '& input': {
                          padding: '16.5px 14px',
                        },
                        '& fieldset': {
                          borderColor: '#ccc',
                        },
                        '&:hover fieldset': {
                          borderColor: '#103e6f',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#103e6f',
                        },
                      },
                    }}
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your Email"
                  />

                  <Typography fontSize={15} fontWeight={600} sx={{ fontFamily: 'Poppins', mt: 6, color: '#103e6f' }}> Password </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      mt: 1,
                      fontFamily: 'Poppins',
                      '& .MuiOutlinedInput-root': {
                        height: 50,
                        '& input': {
                          padding: '16.5px 14px',
                        },
                        '& fieldset': {
                          borderColor: '#ccc',
                        },
                        '&:hover fieldset': {
                          borderColor: '#103e6f',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#103e6f',
                        },
                      },
                    }}
                    fullWidth
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your Password"
                  />
                  <FormControlLabel
                    label={<Typography fontSize={14} sx={{ fontFamily: 'Poppins', color: '#103e6f' }}> Show Password </Typography>}
                    control={<Checkbox size="small" value={showPassword} onChange={(e) => setShowPassword(e.target.checked)} />}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      sx={{
                        fontFamily: 'Poppins',
                        mt: 8,
                        px: 10,
                        borderRadius: 8,
                        fontWeight: '700',
                        textTransform: 'none',
                        backgroundColor: '#09213b',
                        boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: '#5271ff',
                          color: 'white',
                        },
                      }}
                      size="large"
                      loadingPosition="start"
                      loading={loading}
                    >
                      Login
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>



      </div>
    </>
  )
}

export default Login;