import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useState } from 'react';
import { AuthContext } from '../utils/AuthContext';
import { Container, Draggable } from "react-smooth-dnd";
import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { DragHandle } from '@mui/icons-material';
import { arrayMoveImmutable } from 'array-move';
import { updateSubjectPositions } from '../services/SubjectService';
import { updateCurriculumSubjectPositions } from '../services/CurriculumService';

export default function CurriculumSubjectChangePositionsDialog({ open, setOpen, curriculumSubjects, setCurriculumSubjects, handleFetchCurriculum}) {
  const handleClose = () => {
    setOpen(false);
  };

  const { auth } = useContext(AuthContext);

  const onDrop = ({ removedIndex, addedIndex }) => {
    setCurriculumSubjects(curriculumSubjects => arrayMoveImmutable(curriculumSubjects, removedIndex, addedIndex));
  };

  async function handleUpdateSubjectPositions() {
    await updateCurriculumSubjectPositions({ token: auth.token, data: {
      curriculum_subjects: curriculumSubjects
    }});

    handleFetchCurriculum();

    // setShowSubjectForm(false);
    setOpen(false);
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        Change Index Positions
      </DialogTitle>
      <DialogContent>
        <List>
          <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
            {curriculumSubjects.map((curriculumSubject) => (
              <Draggable key={curriculumSubject.id}>
                <ListItem>
                  <ListItemText primary={curriculumSubject.subject.name} />
                  <ListItemSecondaryAction>
                    <ListItemIcon className="drag-handle">
                      <DragHandle />
                    </ListItemIcon>
                  </ListItemSecondaryAction>
                </ListItem>
              </Draggable>
            ))}
          </Container>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} 
        sx={{
                        mr: 1, 
                        color: '#113f70', 
                        '&:hover': {
                          color: '#5271ff' 
                        }
                      }}>Cancel</Button>
        <Button onClick={handleUpdateSubjectPositions}
        sx={{
          mr: 1, 
          color: '#113f70', 
          '&:hover': {
            color: '#5271ff' 
          }
        }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}