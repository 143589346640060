import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Pagination } from '@mui/material';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function SubjectsTable({ subjects, setParentId, setShowSubjectForm, setFormMode, setSelectedSubject, setSubjectToDelete, setShowDeleteDialog, isLoading }) {
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;

  const totalPages = Math.ceil(subjects.length / rowsPerPage);

  const currentPageSubjects = subjects.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '300' }}>Subject</TableCell>
              <TableCell sx={{ fontWeight: '300' }}>Units</TableCell>
              <TableCell align="right" sx={{ fontWeight: '300', paddingRight: 7 }}>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress size={25} />
                </TableCell>
              </TableRow>
            ) : (
              currentPageSubjects.map((subject) => (
                <React.Fragment key={subject.id}>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell scope="row">{subject.name}</TableCell>
                    <TableCell>{subject.units}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      <Tooltip title="Add Sub-Discipline">
                        <IconButton
                          sx={{
                            mr: 1,
                            color: '#113f70',
                            '&:hover': {
                              color: '#5271ff',
                            },
                          }}
                          size="small"
                          onClick={() => {
                            setParentId(subject.id);
                            setShowSubjectForm(true);
                            setFormMode('add');
                          }}
                        >
                          <AddCircleIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete Subject">
                        <IconButton
                          sx={{
                            mr: 1,
                            color: '#113f70',
                            '&:hover': {
                              color: '#5271ff',
                            },
                          }}
                          size="small"
                          onClick={() => {
                            setSubjectToDelete(subject);
                            setShowDeleteDialog(true);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Edit Subject">
                        <IconButton
                          sx={{
                            mr: 1,
                            color: '#113f70',
                            '&:hover': {
                              color: '#5271ff',
                            },
                          }}
                          size="small"
                          onClick={() => {
                            setSelectedSubject(subject);
                            setShowSubjectForm(true);
                            setFormMode('update');
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                  {subject.child_subjects.map((child_subject) => (
                    <TableRow key={child_subject.id}>
                      <TableCell scope="row">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <SubdirectoryArrowRightIcon sx={{ mr: 3, ml: 3 }} />{' '}
                          <span>{child_subject.name}</span>
                        </Box>
                      </TableCell>
                      <TableCell>{child_subject.units}</TableCell>
                      <TableCell>
                        <Button
                          sx={{ mr: 1, visibility: 'hidden' }}
                          size="small"
                        >
                          Add sub-discipline
                        </Button>
                        <Tooltip title="Delete Sub-Discipline">
                          <IconButton
                            sx={{
                              color: '#113f70',
                              '&:hover': {
                                color: '#5271ff',
                              },
                            }}
                            size="small"
                            onClick={() => {
                              setSubjectToDelete(child_subject);
                              setShowDeleteDialog(true);
                            }}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Sub-Discipline">
                          <IconButton
                            sx={{
                              mr: 1,
                              color: '#113f70',
                              '&:hover': {
                                color: '#5271ff',
                              },
                            }}
                            onClick={() => {
                              setSelectedSubject(child_subject);
                              setShowSubjectForm(true);
                              setFormMode('update');
                            }}
                          >
                            <EditTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
        size="small"
        sx={{ mt: 2, display: 'flex', justifyContent: 'right' }}
      />
      
    </Paper>
  );
}
