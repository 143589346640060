import React, { useContext, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AppBar from './AppBar';
import DrawerHeader from './DrawerHeader';
import AlertDialog from './AlertDialog';

import { Avatar } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import BookmarksIcon from '@mui/icons-material/Bookmarks';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';

import { AuthContext } from '../utils/AuthContext';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import global from '../utils/Global'

import CircularProgress from '@mui/material/CircularProgress';
import FaceIcon from '@mui/icons-material/Face';



const drawerWidth = 240;

export default function MainHeader({ open, setOpen }) {
    const { auth, setAuth } = useContext(AuthContext);
    const theme = useTheme();

    const navigate = useNavigate()
    const location = useLocation();
    
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
        localStorage.setItem('drawer-open', true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        localStorage.setItem('drawer-open', false);
    };


    const handleLogout = () => {
        setLoading(true); // Set loading to true when starting the logout process
        // Simulate async operation (replace with your actual logout logic)
        setTimeout(() => {
            localStorage.removeItem('api-token');
            localStorage.removeItem('current-user');
            setAuth({
                token: null,
                user: null
            });
            setLoading(false); // Set loading to false after logout is complete
            navigate('/login');
        }, 1500); // Simulate a delay of 1.5 seconds (for example)
    }

    const handleClickLogout = () => {
        setShowLogoutDialog(true);
    }

    const handleConfirmLogout = () => {
        handleLogout();
        setShowLogoutDialog(false);
    };

    const handleCancelLogout = () => {
        setShowLogoutDialog(false);
    }

    const isActive = (path) => location.pathname === path;



    return (
        <>
            <CssBaseline />

            
            <AppBar
                position="fixed" 
                open={open}
                elevation={0} 
                sx={{ top: 0, left: 0}} 
            > 
                
                 <Toolbar>
                    <IconButton
                        color="#09213b"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>

                    
                </Toolbar> 
                

            </AppBar> 
            

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#09213b', 
                        color: 'white', 
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >

            

                 <DrawerHeader>
                   {/* <IconButton onClick={handleDrawerClose} sx={{ color: 'white' }}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton> */}

                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'left' }}>
                        <Avatar sx={{ mt: '20px', width: 50, height: 50 , mr:1}} alt="JJMMHS" src="/school_logo.png" />
                        <Typography sx={{  mt: '25px', textAlign:"left", color:"white", fontWeight:"500", fontSize:"14px"}} > Jose J. Mariano Memorial High School</Typography>
                        
                    </Box>
                </DrawerHeader> 

                <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography component="div" sx={{ textAlign: 'center', color: 'white', fontWeight: "600", fontSize: "30px" }}>
                        Hi, <br />
                        {auth.user.first_name + ' ' + auth.user.last_name}!
                    </Typography>
                    {/* <Link to={"/users/" + auth.user.id + '?user_update=1'} style={{
                        textDecoration: 'underline',
                        color: isHovered ? '#5271ff' : '#113f70',
                        fontSize: '14px',
                    }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    > Edit </Link> */}
                </Box>
                   

                <Box sx={{ mt: 3, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                    <List>

                        {/* <ListItem onClick={() => {
                            navigate('/search_record');
                        }} key={'Search Student'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor: isActive('/search_record') ? '#113f70' : 'transparent', 
                                    color: isActive('/search_record') ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: '#0e345b',
                                    }
                                }}
                            > 


                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: isActive('/search_record') ? 'white' : 'inherit', // Change icon color if active
                                    }}
                                >
                                    <ManageSearchIcon />

                                </ListItemIcon>
                                <ListItemText primary='Search Record' sx={{ opacity: open ? 1 : 0, color: isActive('/search_record') ? 'white' : 'inherit' }} />
                            </ListItemButton>
                        </ListItem>

                        */}



                        <ListItem onClick={() => {
                            navigate('/student_records');
                        }} key={'Add Student Record'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                 sx={{
                                    minHeight: isActive('/student_records') ? 60 : 60,  
                                    width: isActive('/student_records') ? '100%' : '100%',  
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor: isActive('/student_records') ? '#113f70' : 'transparent', 
                                    borderRadius: '20px',
                                    '&:hover': {
                                      backgroundColor: '#0e345b',
                                      borderRadius: '20px',
                                    }
                                  }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: isActive('/student_records') ? 'white' : 'inherit',
                                    }}
                                >
                                    <PersonIcon />

                                </ListItemIcon>
                                <ListItemText primary='Student Records' sx={{ opacity: open ? 1 : 0, color: isActive('/student_records') ? 'white' : 'inherit' }} />
                            </ListItemButton>
                        </ListItem>



                        <ListItem onClick={() => {
                            navigate('/principals'); // principal page
                        }} key={' '} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: isActive('/principals') ? 60 : 60,  
                                    width: isActive('/principals') ? '100%' : '100%',  
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor: isActive('/principals') ? '#113f70' : 'transparent', 
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: '#0e345b',
                                        borderRadius: '20px',
                                    }
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: isActive('/principals') ? 'white' : 'inherit',
                                    }}
                                >
                                    <FaceIcon />

                                </ListItemIcon>
                                <ListItemText primary='Principals' sx={{ opacity: open ? 1 : 0, color: isActive('/principals ') ? 'white' : 'inherit' }} />
                            </ListItemButton>
                        </ListItem>



                        <ListItem onClick={() => {
                            navigate('/subjects');
                        }} key={'Subjects'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: isActive('/subjects') ? 60 : 60,  
                                    width: isActive('/subjects') ? '100%' : '100%',  
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor: isActive('/subjects') ? '#113f70' : 'transparent', 
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: '#0e345b',
                                        borderRadius: '20px',
                                    }
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: isActive('/subjects') ? 'white' : 'inherit',
                                    }}
                                >
                                    <BookmarksIcon />

                                </ListItemIcon>
                                <ListItemText primary='Subjects' sx={{ opacity: open ? 1 : 0, color: isActive('/subjects') ? 'white' : 'inherit' }} />
                            </ListItemButton>
                        </ListItem>


                        <ListItem onClick={() => {
                            navigate('/curriculums');
                        }} key={'Curriculums'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: isActive('/curriculums') ? 60 : 60,  
                                    width: isActive('/curriculums') ? '100%' : '100%',  
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor: isActive('/curriculums') ? '#113f70' : 'transparent', 
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: '#0e345b',
                                        borderRadius: '20px',
                                    }
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: isActive('/curriculums') ? 'white' : 'inherit', 
                                    }}
                                >
                                    <SchoolIcon />

                                </ListItemIcon>
                                <ListItemText primary='Curriculums' sx={{ opacity: open ? 1 : 0, color: isActive('/curriculums') ? 'white' : 'inherit' }} />
                            </ListItemButton>
                        </ListItem>

                        {
                            auth.user.role_id === 0 &&
                            <ListItem onClick={() => {
                                navigate('/users');
                            }} key={'Users'} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                        sx={{
                                            minHeight: isActive('/users') ? 60 : 60,
                                            width: isActive('/users') ? '100%' : '100%',
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                            backgroundColor: isActive('/users') ? '#113f70' : 'transparent',
                                            borderRadius: '20px',
                                            '&:hover': {
                                                backgroundColor: '#0e345b',
                                                borderRadius: '20px',
                                            }
                                        }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: isActive('/users') ? 'white' : 'inherit', 
                                        }}
                                    >
                                        <PeopleIcon />

                                    </ListItemIcon>
                                    <ListItemText primary='Users' sx={{ opacity: open ? 1 : 0, color: isActive('/users') ? 'white' : 'inherit' }} />
                                </ListItemButton>
                            </ListItem>
                        }

                    </List>

                    <List>
                        <ListItem onClick={handleClickLogout} key={'Logout'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor: 'transparent',
                                    color: 'white',  '&:hover': {
                                        backgroundColor: '#0e345b',
                                        borderRadius: '20px',
                                    }
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: 'white',
                                    }}
                                >
                                    <LogoutIcon />

                                </ListItemIcon>
                                <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>

                <Box sx={{ height: '20px' }}>
                </Box>

            </Drawer>

            <AlertDialog
                title="Logout"
                content={'Are you sure you want to log out? '}
                positiveButtonLabel="Yes"
                negativeButtonLabel="Cancel"
                open={showLogoutDialog}
                setOpen={setShowLogoutDialog}
                callback={handleConfirmLogout}
            />
            
            {loading && (
    <Box 
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',  
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, 
        }}
    >
        <CircularProgress />
    </Box>
)}

        </>
    )
}
