import React, { useEffect, useState, useContext } from 'react';
import {
    Box,
    Button,
    Typography,
    Paper,
} from '@mui/material';
import Main from '../components/Main';
import MainHeader from '../components/MainHeader';
import { AuthContext } from '../utils/AuthContext';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import PrincipalsTable from '../components/PrincipalsTable';
import PrincipalFormDialog from '../components/PrincipalFormDialog';
import AlertDialog from '../components/AlertDialog';
import { deletePrincipal, fetchPrincipals } from '../services/PrincipalService';

function Principals() {
    const { auth } = useContext(AuthContext);

    const [open, setOpen] = useState(localStorage.getItem('drawer-open') === 'true');
    const [showPrincipalForm, setShowPrincipalForm] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [formMode, setFormMode] = useState('add');
    const [fetchingPrincipals, setFetchingPrincipals] = useState(false);
    const [principals, setPrincipals] = useState([]);
    const [selectedPrincipal, setSelectedPrincipal] = useState(null);
    const [principalToDelete, setPrincipalToDelete] = useState(null);

    useEffect(() => {
        handleFetchPrincipals();
    }, []);

    async function handleFetchPrincipals() {
        setFetchingPrincipals(true);
        try {
            const response = await fetchPrincipals({
                token: auth.token,
            });
            setPrincipals(response.data.principals);
        } catch (error) {
            toast.error('Failed to fetch principals');
        } finally {
            setFetchingPrincipals(false);
        }
    }

    async function handleDeletePrincipal() {
        try {
            await deletePrincipal({ token: auth.token, principalId: principalToDelete.id });
            toast.success('Principal Deleted Successfully', { autoClose: 1000, hideProgressBar: true });
            handleFetchPrincipals();
        } catch (error) {
            toast.error('Failed to delete principal');
        } finally {
            setShowDeleteDialog(false);
        }
    }

    const handleEditPrincipal = (principal) => {
        setSelectedPrincipal(principal); 
        setFormMode('update'); 
        setShowPrincipalForm(true); 
    };

    const handleAddPrincipal = () => {
        setSelectedPrincipal(null); 
        setFormMode('add'); 
        setShowPrincipalForm(true); 
    };

    return (
        <Box sx={{ display: 'flex', background: '#ebf2fc', height: '100vh' }}>
            <MainHeader open={open} setOpen={setOpen} />
            <Main open={open} sx={{ pt: 3 }}>
                <Paper
                    elevation={0}
                    sx={{
                        p: 3.4,
                        backgroundColor: '#ffffff',
                        borderRadius: '20px',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: -1, mb: 1 }}>
                        <Typography variant="h4" sx={{ mt: 2.5, fontWeight: 700, color: '#09213b' }}>
                            Principals
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{
                                    mb: -0.2,
                                    padding: 1.7,
                                    backgroundColor: '#113f70',
                                    color: 'white',
                                    borderRadius: '10px',
                                    textTransform: 'none',
                                    '&:hover': {
                                        borderColor: '#3e5bb7',
                                        backgroundColor: '#5271ff',
                                        color: 'white',
                                    },
                                }}
                                onClick={handleAddPrincipal}
                            >
                                Add Principal
                            </Button>
                        </Box>
                    </Box>
                </Paper>

                <PrincipalsTable
                    principals={principals}
                    setFormMode={setFormMode}
                    setSelectedPrincipal={setSelectedPrincipal} 
                    setPrincipalToDelete={setPrincipalToDelete}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setShowPrincipalForm={setShowPrincipalForm}
                    isLoading={fetchingPrincipals}
                />


                <PrincipalFormDialog
                    open={showPrincipalForm}
                    setOpen={setShowPrincipalForm}
                    handleFetchPrincipals={handleFetchPrincipals}
                    principal={selectedPrincipal}
                    setPrincipal={setSelectedPrincipal}
                    mode={formMode}
                />


                <AlertDialog
                    title="Delete Principal"
                    content={`Are you sure you want to delete "${principalToDelete?.first_name} ${principalToDelete?.last_name}"?`}
                    positiveButtonLabel="Delete"
                    negativeButtonLabel="Cancel"
                    open={showDeleteDialog}
                    setOpen={setShowDeleteDialog}
                    callback={handleDeletePrincipal}
                
                />

                <Box sx={{ height: 50 }}></Box>
            </Main>
        </Box>
    );
}

export default Principals;