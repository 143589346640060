import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect } from 'react';
import { useContext } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { toast, ToastContainer } from 'react-toastify';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';


import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { AuthContext } from '../utils/AuthContext';
import { register } from '../services/AuthController';
import Global from '../utils/Global';
import { deleteUser, fetchUser, updateUser } from '../services/UserService';


export default function UserForm() {

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { auth, setAuth } = useContext(AuthContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');

  const [isLoading, setLoading] = useState(false);

  const [fetchingUser, setFetchingUser] = useState(false);

  const navigate = useNavigate();

  /*
  useEffect(() => {
    if (auth.user.role_id === Global.ROLE_ADMIN || auth.user.role_id === Global.ROLE_SUPER_ADMIN) {
      // User is authorized, do nothing
    } else {
      // User is not authorized, redirect
      navigate('/quotation');
    }
  }, []);
  */


  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const checkEmailAvailability = async (email) => {
    try {
      const response = await fetch(`/api/check-email?email=${email}`);
      const data = await response.json();
      return data.exists;
    } catch (error) {
      console.error("Error checking email availability:", error);
      return false;
    }
  };

  const handleEmailChange = async (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);


    setEmailError('');

    if (newEmail.trim() !== '') {

      const emailExists = await checkEmailAvailability(newEmail);
      if (emailExists) {
        setEmailError('This email is already in use');
      }
    }
  };



  useEffect(() => {
    if (id) {
      if (auth.user.role_id !== 0) {
        if (auth.user.id !== id) {
          navigate('/student_records');
        }
      }
    } else {
      if (auth.user.role_id !== 0) {
        navigate('/users');
      }
    }
  }, []);

  useEffect(() => {
    if (id) {
      handleFetchUser();
    }
  }, []);

  // const { auth, setAuth } = useContext(AuthContext);

  async function handleFetchUser() {
    setFetchingUser(true);

    const response = await fetchUser({ token: auth.token, userId: id });

    setFetchingUser(false);

    const user = response.data.user;

    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    // setFirstName(user.first_name);
  }

  function validate() {

    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setPasswordConfirmationError('');
    setPasswordError('');

    let isValid = true;
    if (firstName.trim() === '') {
      setFirstNameError('This field is required');
      isValid = false
    }
    if (lastName.trim() === '') {
      setLastNameError('This field is required');
      isValid = false;
    }

    if (email.trim() === '') {
      setEmailError('This field is required');
      isValid = false;
    }

    if (!id) {
      if (password.trim() === '') {
        setPasswordError('This field is required');
        isValid = false;
      }
      if (passwordConfirmation.trim() === '') {
        setPasswordConfirmationError('This field is required');
        isValid = false;
      }
    }


    if (password.trim() !== '' || passwordConfirmation.trim() !== '') {
      if (password.trim() !== passwordConfirmation.trim()) {
        setPasswordConfirmationError('Password Confirmation does not match');
        isValid = false;
      }

    }

    // if (roleId.trim() === '') {
    //   setRoleIdError('Role is Required')
    //   isValid = false
    // }

    return isValid;
  }

  function handleSubmit(event) {
    let isValid = validate();

    if (!isValid) {
      return
    }


    if (id) {
      handleUpdate();
    } else {
      handleSignUpClick(event);
    }
  }

  async function handleUpdate() {
    const user = {
      'first_name': firstName,
      'last_name': lastName,
      'password': password,
      'email': email
    }

    setLoading(true);

    const response = await updateUser({ token: auth.token, data: user, userId: id });

    setLoading(false);

    toast.success('User Updated', {
      autoClose: 2000,
      hideProgressBar: true,
    });

    const user_update = searchParams.get('user_update');

    if (user_update) {
      setAuth({
        token: auth.token,
        user: response.data.user
      });

      localStorage.setItem('current-user', JSON.stringify(response.data.user));
    }


  }

  function handleSignUpClick(event) {
    event.preventDefault();



    setLoading(true);

    const user = {
      'first_name': firstName,
      'last_name': lastName,
      'password': password,
      'email': email
    }

    register({ user, token: auth.token }).then(res => {
      // toast.success('User Created', {
      //   autoClose: 2000,
      //   hideProgressBar: true,
      // });

      setLoading(false);
      clearForm();

      navigate('/users');
    }).catch(error => {
      toast.error(error.response.data.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });

      setLoading(false);
    }

    )
  }

  function clearForm() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setPasswordConfirmation('');
  }



  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#ebf2fc' }}>


        {
          fetchingUser ? <CircularProgress />
            :


            <Paper sx={{ p: 7, width: '33%', height: 'auto', borderRadius: '20px', align: "center" }}>
              <Typography align="center">
                {/* <img src="/images/logo.png" alt="" id="logo" /> */}
              </Typography>

              <Button startIcon={<ArrowBackIcon />}
                sx={{
                  mt: -5, align: "left", color: '#113f70',
                  '&:hover': {
                    color: '#5271ff',
                  },
                }}
                onClick={() => {
                  navigate('/users');
                }} >Back</Button>

              <Typography sx={{ mt: 1, color: '#113f70', fontWeight: 500 }} component="h1" variant="h4" align="center">
                {id ? 'Update Account' : 'Create Account'}
              </Typography>

              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >

                <TextField
                  name="First Name"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  autoFocus
                  autoComplete="first_name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={firstNameError !== ''}
                  helperText={firstNameError}
                  sx={{
                    mt: 1, width: '80%', alignItems: 'center', color: 'black', backgroundColor: 'white', borderRadius: '10px',
                    '& .MuiInputLabel-root': {
                      color: '#a6a6a6',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#a6a6a6',
                    },
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'white',
                    },
                  }}
                />


                <TextField
                  sx={{
                    mt: 4, width: '80%', color: 'black', backgroundColor: 'white', borderRadius: '10px',
                    '& .MuiInputLabel-root': {
                      color: '#a6a6a6',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#a6a6a6',
                    },
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'white',
                    },
                  }}
                  name="Last Name"
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  autoComplete="last_name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  error={lastNameError !== ''}
                  helperText={lastNameError}
                />



                <TextField
                  sx={{
                    mt: 4,
                    width: '80%',
                    color: 'black',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    '& .MuiInputLabel-root': { color: '#a6a6a6' },
                    '& .MuiInputLabel-root.Mui-focused': { color: '#a6a6a6' },
                    '& .MuiFilledInput-root': { backgroundColor: 'white' },
                  }}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleEmailChange}
                  error={emailError !== ''}
                  helperText={emailError}
                />


                {/* <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Role"
              onChange={(event) => {
                setRoleId(event.target.value)
              }}
              value={roleId}
              error={roleIdError !== ''}
              helperText={roleIdError}
            >
              <MenuItem value={'1'}>User</MenuItem>
              <MenuItem value='2'>Accounting</MenuItem>
              <MenuItem value='3'>Manager</MenuItem>
              <MenuItem value='4'>IT Admin</MenuItem>

            </Select>
          </FormControl> */}

                {/* <TextField
            sx={{ mt: 2 }}
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordError !== ''}
            helperText={passwordError}
          />
          <TextField
            sx={{ mt: 2 }}
            required
            fullWidth
            id="password_confirmation"
            label="Confirm Password"
            name="password_confirmation"
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            error={passwordConfirmationError !== ''}
            helperText={passwordConfirmationError}
          /> */}


                <FormControl
                  sx={{
                    mt: 4,
                    width: '80%',
                    color: 'black',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    '& .MuiInputLabel-root': {
                      color: '#a6a6a6',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#a6a6a6',
                    },
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'white',
                    },
                  }}
                  variant="outlined"
                  error={passwordError !== ''}
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  {passwordError && <FormHelperText>{passwordError}</FormHelperText>}
                </FormControl>

                <FormControl
                  sx={{
                    mt: 4,
                    width: '80%',
                    color: 'black',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    '& .MuiInputLabel-root': {
                      color: '#a6a6a6',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#a6a6a6',
                    },
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'white',
                    },
                  }}
                  variant="outlined"
                  error={passwordConfirmationError !== ''}
                >
                  <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                  <OutlinedInput
                    id="confirm-password"
                    type={showPassword2 ? 'text' : 'password'}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    value={passwordConfirmation}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                  {passwordConfirmationError && <FormHelperText>{passwordConfirmationError}</FormHelperText>}
                </FormControl>


                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: -5,
                    width: '30%',
                    borderRadius: 8,
                    fontWeight: '700',
                    textTransform: 'none',
                    backgroundColor: '#09213b',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#5271ff',
                      color: 'white',
                    },
                  }}
                  size="large"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Paper >
        }
        <ToastContainer />
      </Box>
    </>
  );

}