import ApiClient from "./ApiClient";

export const fetchUsers = ({ token }) => {
    return ApiClient.get('/api/users', {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}

export const fetchUser = ({ token, userId }) => {
  return ApiClient.get('/api/users/' + userId, {
      headers: {
          'Authorization': 'Bearer ' + token
      }
  });
}

export const updateUser = ({ token, userId, data }) => {
  return ApiClient.put('/api/users/' + userId, data, {
      headers: {
          'Authorization': 'Bearer ' + token
      }
  });
}

export const deleteUser = ({ token, userId }) => {
  return ApiClient.delete('/api/users/' + userId, {
      headers: {
          'Authorization': 'Bearer ' + token
      }
  });
}

{/* 
        export const TransferOwnershipToUser = ({ token, userId, data }) => {
        return ApiClient.put('/api/users/' + userId, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }  
*/}