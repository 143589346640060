import ApiClient from "./ApiClient";

export const fetchStudentRecord = ({ token, studentId }) => {
  return ApiClient.get('/api/student_records/' + studentId, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const fetchStudentRecords = ({ token, firstName='', lastName='', dateOfBirth=''}) => {
  return ApiClient.get('/api/student_records?first_name=' + firstName + '&last_name=' + lastName + '&date_of_birth=' + dateOfBirth, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const addStudentRecord = ({ token, data }) => {
  return ApiClient.post('/api/student_records', data, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const updateStudentRecord = ({ token, studentId, data }) => {
  return ApiClient.put('/api/student_records/' + studentId, data, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}

export const deleteStudentRecord = ({ token, studentId }) => {
  return ApiClient.delete('/api/student_records/' + studentId, {
    headers: {
        'Authorization': 'Bearer ' + token
    }
});
}