import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useEffect, useState } from 'react';
import { addCurriculum, updateCurriculum } from '../services/CurriculumService';
import { AuthContext } from '../utils/AuthContext';
import { toast } from 'react-toastify';

export default function CurriculumFormDialog({ open, setOpen, handleFetchCurriculums, curriculum, setCurriculum, mode }) {
  const handleClose = () => {
    setOpen(false);
    setName('');
    setCurriculum(null);
  };

  const { auth } = useContext(AuthContext);

  const [name, setName] = useState('');
  const [nameErr, setNameErr] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (curriculum !== null) {
      setName(curriculum.name);
    }
  }, [curriculum]);

  function validateForm() {
    let strName = name.trim();

    if (strName === '') {
      setNameErr('Name is required');
      return false;
    }

    return true;
  }

  async function handleSubmit() {
    if (mode === 'add') {
      await handleAddCurriculum();
    } else if (mode === 'update') {
      await handleUpdateCurriculums();
    }
  }

  async function handleAddCurriculum() {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    setLoading(true);

    await addCurriculum({
      token: auth.token,
      data: {
        name
      }
    });

    toast.success('Curriculum Added', {
      autoClose: 1000,
      hideProgressBar: true
    });

    handleClose();
    handleFetchCurriculums();

    setName('');
    setLoading(false);
  }

  async function handleUpdateCurriculums() {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    setLoading(true);

    await updateCurriculum({
      token: auth.token,
      curriculumId: curriculum.id,
      data: {
        name
      }
    });

    toast.success('Curriculum Updated', {
      autoClose: 1000,
      hideProgressBar: true
    });

    handleClose();
    handleFetchCurriculums();

    setName('');
    setLoading(false);
  }

  function dialogTitle() {
    return mode === 'add' ? 'Add Curriculum' : 'Edit Curriculum Name';
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(); 
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {dialogTitle()}
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ pt: 2 }}
          onKeyPress={handleKeyPress}
        >
          <TextField
            required
            label="Curriculum Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={Boolean(nameErr)}
            helperText={nameErr}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            padding: 1.7,
            color: '#113f70',
            borderRadius: '10px',
            textTransform: 'none',
            '&:hover': {
              color: '#5271ff'
            }
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            setNameErr('');
            handleSubmit(); 
          }}
          sx={{
            padding: 1.7,
            color: '#113f70',
            borderRadius: '10px',
            textTransform: 'none',
            '&:hover': {
              color: '#5271ff'
            }
          }}
          disabled={isLoading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
