import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  MenuItem,
  Paper,
  Select
} from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import Main from '../components/Main';
import MainHeader from '../components/MainHeader';
import { AuthContext } from '../utils/AuthContext';
import SubjectsTable from '../components/SubjectsTable';
import SearchIcon from '@mui/icons-material/Search';
import SubjectFormDialog from '../components/SubjectFormDialog';
import { deleteSubject, fetchSubjects } from '../services/SubjectService';
import { toast } from 'react-toastify';
import AlertDialog from '../components/AlertDialog';
import SubjectChangePositionsDialog from '../components/SubjectChangePositionsDialog';
import { useDebounce } from 'use-debounce';
import AddIcon from '@mui/icons-material/Add';
import SortByAlphaSharpIcon from '@mui/icons-material/SortByAlphaSharp';

function Subjects() {
  const { auth } = useContext(AuthContext);

  const [open, setOpen] = useState(localStorage.getItem('drawer-open') == 'true');
  const [showSubjectForm, setShowSubjectForm] = useState(false);
  const [showSubjectChangePositionsDialog, setShowSubjectChangePositionsDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [fetchingSubjects, setFetchingSubjects] = useState(false);

  const [subjects, setSubjects] = useState([]);
  const [childSubjects, setChildSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjectToDelete, setSubjectToDelete] = useState(null);
  const [formMode, setFormMode] = useState('add');

  const [parentId, setParentId] = useState(0);

  const [searchQuery, setSearchQuery] = useState('');

  const [debouncedValue] = useDebounce(searchQuery, 500);

  const [sortOrder, setSortOrder] = useState('default'); // 'asc' or 'desc'

  useEffect(() => {
    handleFetchSubjects();
  }, [debouncedValue, /* sortOrder */ ]);

  async function handleFetchSubjects() {
    setFetchingSubjects(true);

    const response = await fetchSubjects({
      token: auth.token,
      search_query: debouncedValue, // Use debouncedValue to trigger search automatically
      // sort_order: sortOrder === 'default' ? null : sortOrder,
    });

    setSubjects(response.data.subjects);
    setFetchingSubjects(false);
  }

  async function handleDeleteSubject() {
    await deleteSubject({ token: auth.token, subjectId: subjectToDelete.id });

    toast.success('Subject Deleted', {
      autoClose: 1000,
      hideProgressBar: true
    });

    handleFetchSubjects();
  }

  return (
    <Box sx={{ display: 'flex', background: '#ebf2fc', height:'auto' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 3 }} >

      <Paper 
      elevation={0}
      sx={{
          p: 3,
          backgroundColor: '#ffffff',
          borderRadius: '20px',
        }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt:-1, mb:1 }}>
        <Typography variant='h4' sx={{ mt:2.5, fontWeight: 700,  color:'#09213b'}}> Subjects </Typography>

        <TextField
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: '300px',
            mt: 1.5,
            mr:2, 
            color: '#ffffff',
            '& .MuiInputBase-root': {
              backgroundColor: '#e9eef6', 
              borderRadius: '20px', 
            },
            '& .MuiFilledInput-root': {
              '&:before, &:after': {
                borderBottom: 'none', 
              },
              '&:hover:not(.Mui-disabled):before': {
                borderBottom: 'none', 
              },
            },
            '& .MuiInputLabel-root': {
              color: '#ffffff',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#ffffff',
            },
          }}
          label="Search..."
          variant="filled"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ color: '#a6a6a6' }} />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: 'black' },
          }}
        />

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Button
              variant='contained'
              startIcon={<AddIcon />}
              sx={{
                mb:-0.2,
                padding: 1.7,
                backgroundColor:'#113f70',
                color: 'white',
                borderRadius: '10px',
                textTransform: 'none',
                '&:hover': {
                  borderColor: '#3e5bb7',
                  backgroundColor: '#5271ff',
                  color: 'white'
                }
              }}
              onClick={() => {
                setParentId(0);
                setShowSubjectForm(true);
                setFormMode('add');
              }}
            >
              Add Subject
            </Button>

            {/* <Select
              value={sortOrder}
              onChange={(e) => {
                setSortOrder(e.target.value);
              }}
              displayEmpty
              variant="outlined"
              sx={{
                ml: 2,
                backgroundColor: '#113f70',
                color: 'white',
                boxShadow: 'none',
                borderRadius: '10px',
                border: 'none',
                textTransform: 'none',
                fontSize: '0.875rem',
                fontWeight:'550',
                '&:hover': {
                  backgroundColor: '#5271ff',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              {/* <MenuItem value="default">Sort</MenuItem>
              <MenuItem value="asc">A to Z</MenuItem>
              <MenuItem value="desc">Z to A</MenuItem>
            </Select> */}

          </Box>
        </Box>

        
        
        </Paper>

        



        <SubjectsTable
          subjects={subjects}
          setParentId={setParentId}
          setShowSubjectForm={setShowSubjectForm}
          setFormMode={setFormMode}
          setSelectedSubject={setSelectedSubject}
          setSubjectToDelete={setSubjectToDelete}
          setShowDeleteDialog={setShowDeleteDialog}
          isLoading={fetchingSubjects}
        />

        {/* Dialogs */}
        <SubjectFormDialog 
          open={showSubjectForm}
          setOpen={setShowSubjectForm}
          parentId={parentId}
          handleFetchSubjects={handleFetchSubjects}
          subject={selectedSubject}
          setSubject={setSelectedSubject}
          mode={formMode}
          setShowSubjectChangePositionsDialog={setShowSubjectChangePositionsDialog}
          setChildSubjects={setChildSubjects}
        />

        <AlertDialog
          title="Delete Subject"
          content={'Do you want to delete "' + (subjectToDelete ? subjectToDelete.name : '') + '"?'}
          positiveButtonLabel="Delete"
          negativeButtonLabel="Cancel"
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          callback={() => {
            handleDeleteSubject();
            setShowDeleteDialog(false);
          }}
        />

        <SubjectChangePositionsDialog
          open={showSubjectChangePositionsDialog}
          setOpen={setShowSubjectChangePositionsDialog}
          subjects={childSubjects}
          setSubjects={setChildSubjects}
          handleFetchSubjects={handleFetchSubjects}
          setShowSubjectForm={setShowSubjectForm}
        />

        <Box sx={{ height: 50 }}></Box>
      </Main>
    </Box>
  );
}

export default Subjects;