import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';

export default function UsersTable({ users, setUserToDelete, setShowDeleteDialog, /*setUserToOwnership, setShowTransferOwnershipDialog,*/ isLoading }) {
  const navigate = useNavigate();

  return (
    <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: '300' }}>User</TableCell>
            <TableCell sx={{ fontWeight: '300' }}>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            isLoading ? (
              <TableRow key="loading">
                <TableCell colSpan={2} align="center">
                  <CircularProgress size={25} />
                </TableCell>
              </TableRow>
            ) : (
              users.length > 0 && users.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope="row" sx={{ fontWeight: '500' }}>
                    {user.first_name + ' ' + user.last_name}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit User">
                      <IconButton
                        sx={{
                          mr: 1,
                          color: '#113f70',
                          '&:hover': {
                            color: '#5271ff',
                          },
                        }}
                        size="small"
                        onClick={() => navigate('/users/' + user.id)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    {user.role_id !== 0 && (
                      <Tooltip title="Delete User">
                        <IconButton
                          sx={{
                            color: '#113f70',
                            '&:hover': {
                              color: '#5271ff',
                            },
                          }}
                          size="small"
                          onClick={() => {
                            setUserToDelete(user);
                            setShowDeleteDialog(true);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}

                    {/* {user.role_id !== 0 && (
                      <Tooltip title="Transfer ownership to this user">
                        <IconButton
                          sx={{
                            ml: 1,
                            color: '#113f70',
                            '&:hover': {
                              color: '#5271ff',
                            },
                          }}
                          size="small"
                          onClick={() => {
                            setUserToOwnership(user);
                            setShowTransferOwnershipDialog(true);
                          }}
                        >
                          <SwitchAccessShortcutIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )} */}
                    
                  </TableCell>
                </TableRow>
              ))
            )
          }
        </TableBody>
      </Table>
    </Paper>
  );
}
