import ApiClient from "./ApiClient";

// Fetch subjects from the backend with optional search query and sort order
export const fetchSubjects = ({ token, search_query = '', sort_order = 'asc' }) => {
    return ApiClient.get('/api/subjects', {
        headers: {
            'Authorization': 'Bearer ' + token
        },
        params: {
            search_query: search_query,
            sort_order: sort_order
        }
    });
}

export const addSubject = ({ token, data }) => {
    return ApiClient.post('/api/subjects', data, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}

export const updateSubject = ({ token, subjectId, data }) => {
    return ApiClient.put('/api/subjects/' + subjectId, data, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}

export const deleteSubject = ({ token, subjectId }) => {
    return ApiClient.delete('/api/subjects/' + subjectId, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}

export const updateSubjectPositions = ({ token, data }) => {
    return ApiClient.put('/api/subjects/update_positions', data, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}