import './App.css';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthContext } from './utils/AuthContext';
import PrivateRoutes from './utils/PrivateRoutes'
import Login from './pages/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Subjects from './pages/Subjects';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Curriculums from './pages/Curriculums';
import CurriculumView from './pages/CurriculumView';
import StudentRecords from './pages/StudentRecords';
import StudentRecordForm from './pages/StudentRecordForm';
import Principals from './pages/Principals';
import SearchRecord from './pages/SearchRecord';
import Users from './pages/Users';
import UserForm from './pages/UserForm';
import TextField from '@mui/material/TextField';

const theme = createTheme({
  palette: {
    primary: {
      main: '#09213b', 
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif', 
  },
  components: {
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
      },
    },
  },
});


function App() {

  const token = localStorage.getItem('api-token');
  const user = JSON.parse(localStorage.getItem('current-user'));

  const [auth, setAuth] = useState({
    token,
    user
  });

  const subDir = '';

  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={{ auth, setAuth }}>
          <BrowserRouter basename={subDir}>
            <Routes>
              <Route element={<Login />} path='/login' />
              <Route element={<Login />} path='/' />
              <Route element={<PrivateRoutes />}>
                <Route element={<Subjects />} path='/subjects' />
                <Route element={<Curriculums />} path='/curriculums' />
                <Route element={<CurriculumView />} path='/curriculums/:id' />
                <Route element={<StudentRecords />} path='/student_records' />
                <Route element={<StudentRecordForm />} path='/student_records/add' />
                <Route element={<StudentRecordForm />} path='/student_records/edit/:id' />
                <Route element={<Principals />} path='/principals' />
                {/* <Route element={<SearchRecord />} path='/search_record' /> */}
                <Route element={<UserForm />} path='/register' />
                <Route element={<UserForm />} path='/users/:id' />
                <Route element={<Users />} path='/users' />
                
              </Route>

            </Routes>
          </BrowserRouter>
        </AuthContext.Provider>
      </ThemeProvider>
      <ToastContainer />

    </>
  );
}

export default App;
