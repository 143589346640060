import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({
  open,
  setOpen,
  title,
  content,
  contentHtml, // Add support for raw HTML content
  positiveButtonLabel,
  negativeButtonLabel,
  callback,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {/* Render raw HTML if contentHtml is provided */}
        {contentHtml ? (
          <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
        ) : (
          content // Render JSX children if no contentHtml is provided
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            padding: 1.7,
            color: '#113f70',
            borderRadius: '10px',
            textTransform: 'none',
            '&:hover': {
              color: '#5271ff',
            },
          }}
        >
          {negativeButtonLabel}
        </Button>
        <Button
          onClick={callback}
          autoFocus
          sx={{
            padding: 1.7,
            color: '#113f70',
            borderRadius: '10px',
            textTransform: 'none',
            '&:hover': {
              color: '#5271ff',
            },
          }}
        >
          {positiveButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
