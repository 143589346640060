import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';



export default function CurriculumsTable({ curriculums, setShowCurriculumForm, setFormMode, setSelectedCurriculum, setCurriculumToDelete, setShowDeleteDialog, isLoading }) {
  const navigate = useNavigate();

  return (
    <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: '300' }}>Curriculum</TableCell>
            <TableCell sx={{ fontWeight: '300' }}>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            isLoading ?
              <TableRow>
                <TableCell colSpan={2} align='center'>
                  <CircularProgress size={25} />
                </TableCell>
              </TableRow>
              :
              curriculums.length > 0 && curriculums.map((curriculum) => (
                <>
                  <TableRow
                    key={curriculum.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell scope="row">
                      {curriculum.name}
                    </TableCell>

                    <TableCell>

                    <Tooltip title='Edit Curriculum Name'>
                        <IconButton                       
                          sx={{
                            mr: 1,
                            color: '#113f70',
                            '&:hover': {
                              color: '#5271ff',
                            },
                          }} size='small' onClick={() => {
                        setSelectedCurriculum(curriculum);
                        setShowCurriculumForm(true);
                        setFormMode('update');
                      }}>
                      <EditIcon fontSize="small"/>
                      </IconButton>
                      </Tooltip>

                      <Tooltip title='Delete Curriculum '>
                        <IconButton                      
                          sx={{
                            mr: 1,
                            color: '#113f70',
                            '&:hover': {
                              color: '#5271ff',
                            },
                          }} size='small' onClick={() => {
                        setCurriculumToDelete(curriculum);
                        setShowDeleteDialog(true);
                      }}>
                      <DeleteIcon fontSize="small"/>
                      </IconButton>
                      </Tooltip>


                      <Tooltip title='Add Subjects under Curriculum '>
                        <IconButton                      
                          sx={{
                            
                            color: '#113f70',
                            '&:hover': {
                              color: '#5271ff',
                            },
                          }} size='small' onClick={() => {
                            navigate('/curriculums/' + curriculum.id)
                          }}>
                            <AddIcon fontSize="medium"/>
                          </IconButton>
                          </Tooltip>
                    </TableCell>

                  </TableRow>
                </>
              ))
          }
        </TableBody>
      </Table>
    </Paper >
  );
}