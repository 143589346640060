import { Box, Button, Typography, Paper } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'
import { AuthContext } from '../utils/AuthContext';
import AlertDialog from '../components/AlertDialog';
import { toast } from 'react-toastify';
import UsersTable from '../components/UsersTable';
import { deleteUser, fetchUsers /*, TransferOwnershipToUser */ } from '../services/UserService';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

function Users() {

  const { auth } = useContext(AuthContext);

  const [open, setOpen] = useState(localStorage.getItem('drawer-open') == 'true');

  const navigate = useNavigate();

  // const [showCurriculumForm, setShowCurriculumForm] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  // const [formMode, setFormMode] = useState('add');

  // const [showTransferOwnershipDialog, setShowTransferOwnershipDialog] = useState(false);

  const [users, setUsers] = useState([]);

  // const [selectedCurriculum, setSelectedCurriculum] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);

  const [fetchingUsers, setFetchingUsers] = useState(false);

  // const [userToTranserOwnership, setUserToOwnership] = useState(null);

  useEffect(() => {
    if (auth.user.role_id !== 0) {
      navigate('/search_record');
    }
  }, []);

  useEffect(() => {
    handleFetchUsers();
  }, []);


  async function handleFetchUsers() {
    setFetchingUsers(true);

    const response = await fetchUsers({ token: auth.token });

    setUsers(response.data.users);

    setFetchingUsers(false);
  }

  async function handleDeleteUser() {
    await deleteUser({ token: auth.token, userId: userToDelete.id });

    toast.success('User Deleted', {
      autoClose: 1000,
      hideProgressBar: true
    });

    handleFetchUsers();
  }

  {/* async function handleTransferOwnershipToUser() {
    await TransferOwnershipToUser({ token: auth.token, userId: userToTranserOwnership.id });

    toast.success('User Ownership Transferred', {
      autoClose: 1000,
      hideProgressBar: true
    });

    handleFetchUsers();
  }*/}


  return (
    <Box sx={{ display: 'flex', background: '#ebf2fc', height: '100vh' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 3 }} >
        <Paper
          elevation={0}
          sx={{
            p: 3,
            backgroundColor: '#ffffff',
            borderRadius: '20px',
          }}>
          {
            auth.user.role_id === 0 &&
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, mb: 1, backgroundColor: '#ffffff' }}>
              <Typography variant='h4' sx={{ mt: 1, fontWeight: 700, color: '#09213b' }}> Users </Typography>

              <Button variant='contained'
                startIcon={<AddIcon />}
                sx={{
                  padding: 1.7,
                  backgroundColor: '#113f70',
                  color: 'white',

                  borderRadius: '10px',
                  textTransform: 'none',
                  '&:hover': {
                    borderColor: '#3e5bb7',
                    backgroundColor: '#5271ff',
                    color: 'white'
                  }
                }} onClick={() => {
                  navigate('/register');
                }}>Add User</Button>
            </Box>
          }
        </Paper>

        {/* 
        <CurriculumsTable
          curriculums={curriculums}
          setShowCurriculumForm={setShowCurriculumForm}
          setFormMode={setFormMode}
          setSelectedCurriculum={setSelectedCurriculum}
          setShowDeleteDialog={setShowDeleteDialog}
          setCurriculumToDelete={setCurriculumToDelete}
          isLoading={fetchUsers} /> */}
        <UsersTable
          users={users}
          isLoading={fetchingUsers}
          setShowDeleteDialog={setShowDeleteDialog}
          setUserToDelete={setUserToDelete}
        // setShowTransferOwnershipDialog={setShowTransferOwnershipDialog} 
        // setUserToOwnership={setUserToOwnership} 
        />


        <AlertDialog title="Delete User"
          content={'Do you want to delete "' + (userToDelete ? userToDelete.first_name : '') + '"?'}
          positiveButtonLabel="Delete"
          negativeButtonLabel="Cancel"
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          callback={() => {
            handleDeleteUser();
            setShowDeleteDialog(false);
          }} />

        {/* <AlertDialog title="Transfer Ownership to User"
          content={'Do you want to transfer ownership to "' + (userToTranserOwnership ? userToTranserOwnership.first_name : '') + '"?'}
          positiveButtonLabel="Yes"
          negativeButtonLabel="No"
          open={showTransferOwnershipDialog}
          setOpen={setShowTransferOwnershipDialog}
          callback={() => {
            handleTransferOwnershipToUser();
            setShowTransferOwnershipDialog(false);
          }} /> */}

        <Box sx={{ height: 50 }}></Box>

      </Main>
    </Box>
  )
}

export default Users;