import {
    Box,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../utils/AuthContext';
import { toast } from 'react-toastify';
import { addPrincipal, updatePrincipal } from '../services/PrincipalService';

export default function PrincipalFormDialog({
    open,
    setOpen,
    handleFetchPrincipals,
    principal,
    setPrincipal,
    mode,
}) {
    const { auth } = useContext(AuthContext);

    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [position, setPosition] = useState('');

    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastNameErr, setLastNameErr] = useState('');
    const [positionErr, setPositionErr] = useState('');

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (mode === 'update' && principal) {
            setFirstName(principal.first_name);
            setMiddleName(principal.middle_name || '');
            setLastName(principal.last_name);
            setPosition(principal.position);
        } else {
            resetFields();
        }
    }, [mode, principal]);

    const handleClose = () => {
        setOpen(false);
        resetFields();
        setPrincipal(null);
    };

    const resetFields = () => {
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setPosition('');
        setFirstNameErr('');
        setLastNameErr('');
        setPositionErr('');
    };

    const validateForm = () => {
        let valid = true;

        if (firstName.trim() === '') {
            setFirstNameErr('First name is required');
            valid = false;
        }

        if (lastName.trim() === '') {
            setLastNameErr('Last name is required');
            valid = false;
        }

        if (position.trim() === '') {
            setPositionErr('Position is required');
            valid = false;
        }

        return valid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setLoading(true);

        try {
            if (mode === 'add') {
                await addPrincipal({
                    token: auth.token,
                    data: {
                        first_name: firstName.trim(),
                        middle_name: middleName.trim() || null,
                        last_name: lastName.trim(),
                        position: position.trim(),
                    },
                });
                toast.success('Principal Added Successfully', {
                    autoClose: 1000,
                    hideProgressBar: true,
                });
            } else if (mode === 'update') {
                await updatePrincipal({
                    token: auth.token,
                    principalId: principal.id,
                    data: {
                        first_name: firstName.trim(),
                        middle_name: middleName.trim() || null,
                        last_name: lastName.trim(),
                        position: position.trim(),
                    },
                });
                toast.success('Principal Updated Successfully', {
                    autoClose: 1000,
                    hideProgressBar: true,
                });
            }

            handleFetchPrincipals(); 
            handleClose(); 
        } catch (error) {
            console.error('Error submitting principal:', error);
            toast.error('Failed to save principal. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const dialogTitle = mode === 'add' ? 'Add Principal' : 'Edit Principal';

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{ pt: 2 }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault(); 
                            handleSubmit(); 
                        }
                    }}
                >
                    <TextField
                        required
                        label="First Name"
                        fullWidth
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        error={Boolean(firstNameErr)}
                        helperText={firstNameErr}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Middle Name (Optional)"
                        fullWidth
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        required
                        label="Last Name"
                        fullWidth
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        error={Boolean(lastNameErr)}
                        helperText={lastNameErr}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        required
                        label="Position"
                        fullWidth
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        error={Boolean(positionErr)}
                        helperText={positionErr}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={isLoading}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );

}