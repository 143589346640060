import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useEffect, useState } from 'react';
import { addSubject, updateSubject } from '../services/SubjectService';
import { AuthContext } from '../utils/AuthContext';
import { toast } from 'react-toastify';

export default function SubjectFormDialog({ open, setOpen, parentId, handleFetchSubjects, subject, setSubject, mode, setShowSubjectChangePositionsDialog, setChildSubjects }) {
  const handleClose = () => {
    setOpen(false);
    setName('');
    setUnits('');
    setSubject(null);
  };

  const { auth } = useContext(AuthContext);

  const [name, setName] = useState('');
  const [units, setUnits] = useState('');

  const [nameErr, setNameErr] = useState('');
  const [unitsErr, setUnitsErr] = useState('');

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (subject !== null) {
      setName(subject.name);
      setUnits(subject.units);
    }
  }, [subject]);

  function validateForm() {
    let strName = name.trim();
    let strUnits = units.toString().trim();

    if (strName === '') {
      setNameErr('Name is required');
      return false;
    }

    if (strUnits === '') {
      setUnitsErr('Units are required');
      return false;
    }

    return true;
  }

  async function handleSubmit() {
    if (mode === 'add') {
      await handleAddSubject();
    } else if (mode === 'update') {
      await handleUpdateSubject();
    }
  }

  async function handleAddSubject() {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    setLoading(true);

    await addSubject({
      token: auth.token,
      data: {
        name,
        units,
        parent_id: parentId
      }
    });

    toast.success('Subject Added', {
      autoClose: 1000,
      hideProgressBar: true
    });

    handleClose();
    handleFetchSubjects();

    setName('');
    setUnits('');

    setLoading(false);
  }

  async function handleUpdateSubject() {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    setLoading(true);

    await updateSubject({
      token: auth.token,
      subjectId: subject.id,
      data: {
        name,
        units
      }
    });

    toast.success('Subject Updated', {
      autoClose: 1000,
      hideProgressBar: true
    });

    handleClose();
    handleFetchSubjects();

    setName('');
    setUnits('');

    setLoading(false);
  }

  function dialogTitle() {
    if (mode === 'add') {
      return parentId !== 0 ? 'Add Sub-Discipline' : 'Add Subject';
    }

    if (mode === 'update') {
      return 'Edit Subject';
    }
  }


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      handleSubmit(); 
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {dialogTitle()}
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ pt: 2 }}
          onKeyPress={handleKeyPress} // Add the key press handler here
        >
          <TextField
            required
            label="Subject Name"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            error={Boolean(nameErr)}
            helperText={nameErr}
          />
          <TextField
            type='number'
            sx={{ mt: 2 }}
            required
            label="Units"
            fullWidth
            value={units}
            onChange={(e) => {
              setUnits(e.target.value);
            }}
            error={Boolean(unitsErr)}
            helperText={unitsErr}
          />

          <Button
            onClick={() => {
              setChildSubjects(subject.child_subjects);
              setShowSubjectChangePositionsDialog(true);
            }}
            sx={{
              mt: 2,
              display: (mode === 'update' && subject && subject.parent_id == null && subject.child_subjects.length > 0) ? 'inline-block' : 'none'
            }}
          >
            Change Sub-Discipline Positions
          </Button>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            padding: 1.7,
            color: '#113f70',
            borderRadius: '10px',
            textTransform: 'none',
            '&:hover': {
              color: '#5271ff'
            }
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            setNameErr('');
            setUnitsErr('');

            setTimeout(() => {
              handleSubmit();
            }, 100);
          }}
          sx={{
            padding: 1.7,
            color: '#113f70',
            borderRadius: '10px',
            textTransform: 'none',
            '&:hover': {
              color: '#5271ff'
            }
          }}
          disabled={isLoading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
