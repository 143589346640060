import { Box, Button, CircularProgress, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'
import { AuthContext } from '../utils/AuthContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCurriculum, removeCurriculumSubject } from '../services/CurriculumService';
import SelectSubjectDialog from '../components/SelectSubjectDialog';
import AlertDialog from '../components/AlertDialog';
import { toast } from 'react-toastify';
import CurriculumSubjectChangePositionsDialog from '../components/CurriculumSubjectChangePositionsDialog';
import EditIcon from '@mui/icons-material/Edit';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


function CurriculumView() {

  const { auth } = useContext(AuthContext);

  const { id } = useParams();

  const [open, setOpen] = useState(localStorage.getItem('drawer-open') == 'true');

  const [showSelectSubjectDialog, setShowSelectSubjectDialog] = useState(false);

  const navigate = useNavigate();

  const [curriculum, setCurriculum] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const [subjectToRemove, setSubjectToRemove] = useState(null);
  const [showRemoveSubjectDialog, setShowRemoveSubjectDialog] = useState(null);

  const [year, setYear] = useState(0);

  const [selectedCurriculumSubjects, setSelectedCurriculumSubjects] = useState([]);
  const [showCurriculumSubjectChangePositionsDialog, setShowCurriculumSubjectChangePositionsDialog] = useState(false);


  useEffect(() => {
    handleFetchCurriculum();
  }, []);



  async function handleFetchCurriculum() {
    setLoading(true);

    const response = await fetchCurriculum({ token: auth.token, curriculumId: id });

    setCurriculum(response.data.curriculum);

    setLoading(false);
  }

  async function handleRemoveCurriculumSubject() {
    await removeCurriculumSubject({
      token: auth.token, data: {
        subject_id: subjectToRemove.id,
        curriculum_id: curriculum.id,
      }
    });

    handleFetchCurriculum();

    toast.success('Subject Removed', {
      autoClose: 1000,
      hideProgressBar: true
    });


  }


  return (
    <Box sx={{ display: 'flex', background: '#ebf2fc', height:'auto' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 2 }} >


        <Box sx={{ mt: 1 }}>
          <Button startIcon={<ArrowBackIcon />} onClick={() => {
            navigate('/curriculums');}} 
            sx={{mt: 4, color:'#113f70', 
              '&:hover': {
              borderColor: '#3e5bb7',
              backgroundColor: '#5271ff',
              color: 'white',
            },}}> Back </Button>

        </Box>
        {
          isLoading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
              <CircularProgress />
            </Box>
            :
            <Box>
              <Typography variant='h4' sx={{ mt: 2, mb: 1, fontSize: 30, fontWeight: 700, color: '#09213b' }}>Curriculum View: {curriculum && curriculum.name}</Typography>

              <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
                <Typography sx={{ fontSize: 18, fontWeight: '600' }}> First Year </Typography>
                <Box sx={{ mt: 1 }}>
                  <Button variant='contained' sx={{
                    ml: 1,
                    backgroundColor: '#113f70',
                    color: 'white',
                    fontWeight: '400',
                    boxShadow: 'none',
                    borderRadius: '7px',
                    textTransform: 'none',
                    '&:hover': {
                      borderColor: '#3e5bb7',
                      backgroundColor: '#5271ff',
                      color: 'white'
                    }
                  }} onClick={() => {
                    setShowSelectSubjectDialog(true);
                    setYear(1);
                  }}>Add Subject</Button>

                  <Button variant='contained' 
                    sx={{
                      ml: 2,
                      backgroundColor: '#113f70',
                      color: 'white',
                      fontWeight: '400',
                      boxShadow: 'none',
                      borderRadius: '7px',
                      textTransform: 'none',
                      '&:hover': {
                        borderColor: '#3e5bb7',
                        backgroundColor: '#5271ff',
                        color: 'white'
                      }
                    }} onClick={() => {
                    setShowCurriculumSubjectChangePositionsDialog(true);
                    setSelectedCurriculumSubjects(curriculum['curriculum_subjects_1']);
                  }}>Change Positions</Button>

                </Box>

                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={'30%'} sx={{ fontWeight: '300' }} >Subject</TableCell>
                      <TableCell sx={{ fontWeight: '300' }} >Units</TableCell>
                      <TableCell sx={{ fontWeight: '300' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      curriculum && curriculum['curriculum_subjects_1'].map(curriculum_subject => (
                        <TableRow>
                          <TableCell sx={{ fontWeight: '500' }}>{curriculum_subject.subject.name}</TableCell>
                          <TableCell sx={{ fontWeight: '500' }}>{curriculum_subject.subject.units}</TableCell>
                          <TableCell>
                            <Tooltip title='Delete Subject '>
                              <IconButton
                                sx={{
                                  mr: 1,
                                  color: '#113f70',
                                  '&:hover': {
                                    color: '#5271ff',
                                  },
                                }} size='small'
                                onClick={() => {
                                  setSubjectToRemove(curriculum_subject.subject);
                                  setShowRemoveSubjectDialog(true);
                                }}>
                                <DeleteIcon fontSize='medium'/>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    }

                  </TableBody>
                </Table>
              </Paper>

              <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
                <Typography sx={{ fontSize: 18, fontWeight: '600' }}> Second Year </Typography>
                <Box sx={{ mt: 1 }}>

                  <Button variant='contained' 
                  sx={{
                    ml: 1,
                    backgroundColor: '#113f70',
                    color: 'white',
                    fontWeight: '400',
                    boxShadow: 'none',
                    borderRadius: '7px',
                    textTransform: 'none',
                    '&:hover': {
                      borderColor: '#3e5bb7',
                      backgroundColor: '#5271ff',
                      color: 'white'
                    }
                    
                  }}
                  onClick={() => {
                    setShowSelectSubjectDialog(true);
                    setYear(2);
                  }}>Add Subject</Button>
                  
                  <Button variant='contained' 
                  sx={{
                    ml: 2,
                    backgroundColor: '#113f70',
                    color: 'white',
                    fontWeight: '400',
                    boxShadow: 'none',
                    borderRadius: '7px',
                    textTransform: 'none',
                    '&:hover': {
                      borderColor: '#3e5bb7',
                      backgroundColor: '#5271ff',
                      color: 'white'
                    }
                  }}
                  onClick={() => {
                    setShowCurriculumSubjectChangePositionsDialog(true);
                    setSelectedCurriculumSubjects(curriculum['curriculum_subjects_2']);
                  }}>Change Positions</Button>
                </Box>

                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell width={'30%'} sx={{ fontWeight: '300' }} >Subject</TableCell>
                      <TableCell sx={{ fontWeight: '300' }} >Units</TableCell>
                      <TableCell sx={{ fontWeight: '300' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      curriculum && curriculum['curriculum_subjects_2'].map(curriculum_subject => (
                        <TableRow>
                          <TableCell sx={{ fontWeight: '500' }}>{curriculum_subject.subject.name}</TableCell>
                          <TableCell sx={{ fontWeight: '500' }}>{curriculum_subject.subject.units}</TableCell>
                          <TableCell>
                          <Tooltip title='Delete Subject '>
                              <IconButton
                                sx={{
                                  mr: 1,
                                  color: '#113f70',
                                  '&:hover': {
                                    color: '#5271ff',
                                  },
                                }} size='small'
                            onClick={() => {
                              setSubjectToRemove(curriculum_subject.subject);
                              setShowRemoveSubjectDialog(true);
                            }}>
                              <DeleteIcon fontSize='medium'/>
                            </IconButton>
                            </Tooltip>

                          </TableCell>
                        </TableRow>
                      ))
                    }

                  </TableBody>
                </Table>
              </Paper>

              <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
                <Typography sx={{ fontSize: 18, fontWeight: '600' }}> Third Year </Typography>
                <Box sx={{ mt: 1 }}>

                  <Button variant='contained' 
                  sx={{
                    ml: 1,
                    backgroundColor: '#113f70',
                    color: 'white',
                    fontWeight: '400',
                    boxShadow: 'none',
                    borderRadius: '7px',
                    textTransform: 'none',
                    '&:hover': {
                      borderColor: '#3e5bb7',
                      backgroundColor: '#5271ff',
                      color: 'white'
                    }
                  }}
                  onClick={() => {
                    setShowSelectSubjectDialog(true);
                    setYear(3);
                  }}>Add Subject</Button>

                  <Button variant='contained' 
                  sx={{
                    ml: 2,
                    backgroundColor: '#113f70',
                    color: 'white',
                    fontWeight: '400',
                    boxShadow: 'none',
                    borderRadius: '7px',
                    textTransform: 'none',
                    '&:hover': {
                      borderColor: '#3e5bb7',
                      backgroundColor: '#5271ff',
                      color: 'white'
                    }
                  }}
                  onClick={() => {
                    setShowCurriculumSubjectChangePositionsDialog(true);
                    setSelectedCurriculumSubjects(curriculum['curriculum_subjects_3']);
                  }}>Change Positions</Button>

                </Box>

                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell width={'30%'} sx={{ fontWeight: '300' }} >Subject</TableCell>
                      <TableCell sx={{ fontWeight: '300' }} >Units</TableCell>
                      <TableCell sx={{ fontWeight: '300' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      curriculum && curriculum['curriculum_subjects_3'].map(curriculum_subject => (
                        <TableRow>
                          <TableCell sx={{ fontWeight: '500' }}>{curriculum_subject.subject.name}</TableCell>
                          <TableCell sx={{ fontWeight: '500' }}>{curriculum_subject.subject.units}</TableCell>
                          <TableCell>
                          <Tooltip title='Delete Subject '>
                              <IconButton
                                sx={{
                                  mr: 1,
                                  color: '#113f70',
                                  '&:hover': {
                                    color: '#5271ff',
                                  },
                                }} size='small'
                            onClick={() => {
                              setSubjectToRemove(curriculum_subject.subject);
                              setShowRemoveSubjectDialog(true);
                            }}>
                            <DeleteIcon fontSize='medium'/>
                            </IconButton>
                            </Tooltip>
                            
                          </TableCell>
                        </TableRow>
                      ))
                    }

                  </TableBody>
                </Table>
              </Paper>

              <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
                <Typography sx={{ fontSize: 18, fontWeight: '600' }}> Fourth Year </Typography>
                <Box sx={{ mt: 1 }}>

                  <Button variant='contained' 
                  sx={{
                    ml: 1,
                    backgroundColor: '#113f70',
                    color: 'white',
                    fontWeight: '400',
                    boxShadow: 'none',
                    borderRadius: '7px',
                    textTransform: 'none',
                    '&:hover': {
                      borderColor: '#3e5bb7',
                      backgroundColor: '#5271ff',
                      color: 'white'
                    }
                  }}
                  onClick={() => {
                    setShowSelectSubjectDialog(true);
                    setYear(4);
                  }}>Add Subject</Button>

                  <Button variant='contained' 
                  sx={{
                    ml: 2,
                    backgroundColor: '#113f70',
                    color: 'white',
                    fontWeight: '400',
                    boxShadow: 'none',
                    borderRadius: '7px',
                    textTransform: 'none',
                    '&:hover': {
                      borderColor: '#3e5bb7',
                      backgroundColor: '#5271ff',
                      color: 'white'
                    }
                  }}
                  onClick={() => {
                    setShowCurriculumSubjectChangePositionsDialog(true);
                    setSelectedCurriculumSubjects(curriculum['curriculum_subjects_4']);
                  }}>Change Positions</Button>
                </Box>

                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell width={'30%'} sx={{ fontWeight: '300' }} >Subject</TableCell>
                      <TableCell sx={{ fontWeight: '300' }} >Units</TableCell>
                      <TableCell sx={{ fontWeight: '300' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      curriculum && curriculum['curriculum_subjects_4'].map(curriculum_subject => (
                        <TableRow>
                          <TableCell sx={{ fontWeight: '500' }}>{curriculum_subject.subject.name}</TableCell>
                          <TableCell sx={{ fontWeight: '500' }}>{curriculum_subject.subject.units}</TableCell>
                          <TableCell>
                          <Tooltip title='Delete Subject '>
                              <IconButton
                                sx={{
                                  mr: 1,
                                  color: '#113f70',
                                  '&:hover': {
                                    color: '#5271ff',
                                  },
                                }} size='small'
                            onClick={() => {
                              setSubjectToRemove(curriculum_subject.subject);
                              setShowRemoveSubjectDialog(true);
                            }}>
                            <DeleteIcon fontSize='medium'/>
                            </IconButton>
                            </Tooltip>
                            
                          </TableCell>
                        </TableRow>
                      ))
                    }

                  </TableBody>
                </Table>
              </Paper>


            </Box>
        }



        {/* Dialogs */}
        <SelectSubjectDialog
          open={showSelectSubjectDialog}
          setOpen={setShowSelectSubjectDialog}
          curriculumId={curriculum ? curriculum.id : ''}
          year={year}
          handleFetchCurriculum={handleFetchCurriculum}
          curriculum={curriculum} />

        <CurriculumSubjectChangePositionsDialog
          open={showCurriculumSubjectChangePositionsDialog}
          setOpen={setShowCurriculumSubjectChangePositionsDialog}
          curriculumSubjects={selectedCurriculumSubjects}
          setCurriculumSubjects={setSelectedCurriculumSubjects}
          handleFetchCurriculum={handleFetchCurriculum}
        />

        <AlertDialog title="Remove Subject"
          content={'Do you want to remove "' + (subjectToRemove ? subjectToRemove.name : '') + '"?'}
          positiveButtonLabel="Remove"
          negativeButtonLabel="Cancel"
          open={showRemoveSubjectDialog}
          setOpen={setShowRemoveSubjectDialog}
          callback={() => {
            handleRemoveCurriculumSubject();
            setShowRemoveSubjectDialog(false);
          }} />

        <Box sx={{ height: 50 }}></Box>

      </Main>
    </Box>
  )
}

export default CurriculumView;