import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../utils/AuthContext';
import { fetchStudentRecord } from '../services/StudentRecordService';
import { fetchSubjects } from '../services/SubjectService'; 

const ConfirmationDialog = ({ open, handleClose, handleConfirm, studentRecord: initialStudentRecord }) => {
  const { auth } = useContext(AuthContext);
  const { id } = useParams();
  const [studentRecord, setStudentRecord] = useState(initialStudentRecord);
  const [subjects, setSubjects] = useState([]); 

  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const itemsPerPage = 1; // One year per page

  useEffect(() => {
    fetchAllSubjects(); 
    if (id && !initialStudentRecord) {
      handleFetchStudentRecord();
    } else if (initialStudentRecord) {
      setStudentRecordWithSubjectNames(initialStudentRecord);
    }
  }, [id, initialStudentRecord]);

  const fetchAllSubjects = async () => {
    try {
      const response = await fetchSubjects({ token: auth.token });
      setSubjects(response.data.subjects);
    } catch (error) {
      console.error('Failed to fetch subjects:', error);
    }
  };

  const handleFetchStudentRecord = async () => {
    try {
      const response = await fetchStudentRecord({ token: auth.token, studentId: id });
      const student = response.data.student;
      setStudentRecordWithSubjectNames(student);
    } catch (error) {
      console.error('Failed to fetch student record:', error);
    }
  };

  const setStudentRecordWithSubjectNames = (record) => {
    const updatedStudentRecord = {
      ...record,
      student_school_years: record.student_school_years.map(schoolYear => ({
        ...schoolYear,
        grades: schoolYear.grades.map(grade => {
          let subjectName = subjects.find(sub => sub.id === grade.subject_id)?.name;
          if (!subjectName) {
            for (const parentSubject of subjects) {
              const childSubject = parentSubject.child_subjects?.find(child => child.id === grade.subject_id);
              if (childSubject) {
                subjectName = childSubject.name;
                break;
              }
            }
          }
          return {
            ...grade,
            subject_name: subjectName || 'Unknown Subject'
          };
        })
      }))
    };
    setStudentRecord(updatedStudentRecord);
  };

  if (!studentRecord) {
    return null;
  }

  // Pagination logic for slicing data for school years (only show one year per page after the first page)
  const indexOfLastItem = (currentPage - 2) * itemsPerPage + itemsPerPage;
  const currentSchoolYears = studentRecord.student_school_years.slice(indexOfLastItem - itemsPerPage, indexOfLastItem);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="confirmation-dialog-title">
      <DialogTitle id="confirmation-dialog-title" sx={{ fontWeight: 500, mt: 1 }}> Confirm Student Information </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm the Student Record details before proceeding:
        </DialogContentText>

        {/* Page 1: Student Information */}
        {currentPage === 1 && (
          <Box 
          sx={{ 
            mt: 2, 
            p: 2, 
            backgroundColor: '#f5f5f5',  
            borderRadius: '10px', 
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)' 
          }}>
            <Typography variant="body1" fontSize={18}><strong>LRN:</strong> {studentRecord.lrn}</Typography>
            <Typography variant="body1"fontSize={18}><strong>Name:</strong> {studentRecord.first_name} {studentRecord.middle_name} {studentRecord.last_name}</Typography>
            <Typography variant="body1"fontSize={18}><strong>Date of Birth:</strong> {studentRecord.date_of_birth}</Typography>
            {/* <Typography variant="body1" sx={{ mt: 2 }}><strong>Place of Birth:</strong> {studentRecord.place_of_birth}</Typography> */}
            <Typography variant="body1" sx={{ mt: 2 }}><strong>Province:</strong> {studentRecord.province}</Typography>
            <Typography variant="body1"><strong>Town:</strong> {studentRecord.town}</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}><strong>Barrio:</strong> {studentRecord.barrio}</Typography>
            <Typography variant="body1"><strong>Parent/Guardian:</strong> {studentRecord.parent_guardian}</Typography>
            <Typography variant="body1"><strong>Occupation:</strong> {studentRecord.parent_guardian_occupation}</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}><strong>Address:</strong> {studentRecord.parent_guardian_address}</Typography>
            <Typography variant="body1"><strong>Intermediate Course Completed at:</strong> {studentRecord.intermediate_course_completed_at}</Typography>
            <Typography variant="body1"><strong>Year:</strong> {studentRecord.intermediate_course_completed_year}</Typography>
            <Typography variant="body1"><strong>General Average:</strong> {studentRecord.intermediate_course_completed_gen_ave}</Typography>
          </Box>
        )}

        {/* Pages 2 onwards: School Year Information */}
        {currentPage > 1 && currentSchoolYears.map((student_school_year, index) => (
          <Box key={index}
            sx={{
              mt: 2,
              p: 2,
              backgroundColor: '#f5f5f5',
              borderRadius: '10px',
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)'
            }}>
            <Typography variant="body1"><strong>Year Level:</strong> {student_school_year.student_level}</Typography>
            <Typography variant="body1">
      <strong>Total No. of Years in School to Date:</strong>{' '}
      {7 + (currentPage - 2)} {/* Adjusting to show 7, 8, 9, 10 on pages 2 to 5 */}
    </Typography>
            <Typography variant="body1"><strong>School Year:</strong> {student_school_year.school_year}</Typography>
            <Typography variant="body1"><strong>School:</strong> {student_school_year.school}</Typography>
            <Typography variant="body1"><strong>Total Days of School:</strong> {student_school_year.total_days_of_school}</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}><strong>Total Days Present:</strong> {student_school_year.total_days_present}</Typography>

            {/* Grades Table */}
            {student_school_year.grades && (
              <TableContainer component={Paper} sx={{ mt: 2, borderRadius: 3}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Subject</strong></TableCell>
                      <TableCell><strong>Final Grade</strong></TableCell>
                      <TableCell><strong>Action Taken</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {student_school_year.grades.map((grade, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{grade.subject_name}</TableCell>
                        <TableCell>{grade.final_grade === 0 ? '' : grade.final_grade}</TableCell>
                        <TableCell>{grade.action_taken}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        ))}

        {/* Pagination Controls */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            sx={{
              color: '#113f70',
              '&:hover': {
                color: '#5271ff',
              },
            }}
          >
            Previous
          </Button>
          <Typography variant="body2">Page {currentPage}</Typography>
          <Button
            onClick={() => setCurrentPage((prev) => {
              const totalPages = 1 + studentRecord.student_school_years.length; // 1 for the student info page + school year pages
              return Math.min(prev + 1, totalPages);
            })}
            disabled={currentPage === 1 + studentRecord.student_school_years.length}
            sx={{
              color: '#113f70',
              '&:hover': {
                color: '#5271ff',
              },
            }}
          >
            Next
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">Cancel</Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
